import React, { useEffect } from 'react'
import Header from '../Header'
import PackageCard from '../PackageCard'

export default function SriLanka() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
    const data = [
        {
          title: "SriLanka",
          img: "https://metro.co.uk/wp-content/uploads/2019/10/GettyImages-824079764.jpg?quality=90&strip=all",
          interval: "5 days / 4 Nights",
          price: "₹30,000",
          description: {
            about:
               "Empty",
            departure: " Airport",
            departureTime: "6:00 pm",
          },
          timeline: [
            {
              day: "Day 1",
              place: "Arrival in Colombo - Kandy (3 hours)",
              about: "Arrive at Colombo Bandaranayake International Airport. Transfer to Kandy. Visit Pinnawala Elephant Orphanage en route. Check-in and explore Kandy, including Temple of Tooth Relic, Peradeniya Botanical Gardens, and Gem Museum. Attend a traditional cultural dance show. Overnight stay in Kandy.",
            },
            {
              day: "Day 2",
              place: "Kandy – Nuwara Eliya (2.5 hours)",
              about: "After breakfast, travel to Nuwara Eliya, known as the City of Lights. Check-in and explore Victoria Park, the golf course, and the trout streams. Enjoy boat riding and fishing at Lake Gregory. Visit a tea factory and explore attractions like Haggala Gardens and Seetha Eliya. Overnight stay in Nuwara Eliya.",
            },
            {
              day: "Day 3",
              place: "Nuwara Eliya – Bentota (5.5 hours)",
              about: "After breakfast, continue sightseeing in Nuwara Eliya. Later, proceed to Bentota, known as the City of Golden Beaches. Check-in and enjoy a city tour with activities. Visit popular beaches, go boating in River Madhu, and explore Turtle Hatchery. Overnight stay in Bentota.",
            },
            {
              day: "Day 4",
              place: "Bentota – Colombo (2 hours)",
              about: "After breakfast, head back to Colombo, the commercial capital of Sri Lanka. Check-in and explore the city's tourist attractions, including Viharamahadevi Park, Galle Face Green, Fort district, National Museum, and more. Evening shopping at popular markets. Overnight stay in Colombo.",
            },
            {
              day: "Day 5",
              place: "Departure from Colombo",
              about: "After breakfast, free time, and check-out, transfer to the airport for the flight to your onward destination.",
            },
          ],
        },
    ]
  return (
    <div>
        <Header/>
         <h1> Singapore</h1>
         <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </div>
  )
}
