
import React from 'react'
import Header from '../Header'
import img1 from'./Internationalimg/CANADA.jpg'
import img2 from'./Internationalimg/INDIA TO AUS.jpg'
import img3 from'./Internationalimg/INDIA TO CANADA.jpg'
import img4 from'./Internationalimg/INDIA TO DUBAI.jpg'
import img5 from'./Internationalimg/INDIA TO EUROPE.jpg'
import img6 from'./Internationalimg/INDIA TO New Zealand.jpg'
import img7 from'./Internationalimg/INDIA TO UK.jpg'
import img8 from'./Internationalimg/USA.jpg'
import Card from '../Card'

export default function DomesticFlight() {
    const flights = [
        { url: img1  },
        { url: img2  },
        { url: img3  },
        { url: img4  },
        { url: img5  },
        { url: img6  },
        { url: img7  },
        { url: img8  },

    ]
  return (
    <>
      <Header/>
      <h1>Domestic Flights</h1>
      <div className="container my-3">
        <div className="row" style={{ marginTop: "50px" }}>
          <div className="container">
            <div className="row">
              {flights.map((element, i) => {
                return (
                  <div className="col-md-4" key={i}>
                    <Card img={element.url} title={""} doc={""} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
