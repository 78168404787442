// Card.js

export default function Card() {
  return (
    <div >
      <div className="flex justify-center">
      <div
        className="h-[240px]   float-left 
							-mt-10 w-80 flex-col 
							rounded-xl bg-yellow-200 bg-clip-border 
							text-gray-700 shadow-2xl  md:w-96  "
      >
        <div className="p-6">
          <h5
            className="text-center mr-4 mb-2 
								block font-sans text-xl 
								font-semibold text-blue-gray-900 
								antialiased"
          >
            Reach Us At
          </h5>
          <ul>
            <li className="mt-2">
              <span>
                <i className="fa fa-phone mr-2"></i>{" "}
              </span>
              <a  style={{ color: "black" }} href="tel:7015293565">7015293565</a>
            </li>
            <li className="mt-2">
              <span>
                <i className="fa fa-envelope mr-2"></i>{" "}
              </span>
              <a
                style={{ color: "black" }}
                href="mailto:admiretourism@gmail.com"
              >
                admiretourism@gmail.com
              </a>
            </li>

            <li className="mt-2">
              <span>
                <i className="fa-solid fa-map-pin mr-2"></i>
              </span>
              <a
                style={{ color: "black" }}
                href="https://maps.app.goo.gl/BM29s7vmi91Qd7Sm9"
              >
                #SCF 11, 1st floor, Sarojini colony, Phase 1,
                <span className="pl-1">
                  Opp.Head office,Near BusStand, Yamunanagar
                </span>
              </a>
            </li>
            <li className="mt-2">
              <span>
                <i className="fa-solid fa-map-pin mr-2"></i>
              </span>
              <a
                style={{ color: "black" }}
                href="https://maps.app.goo.gl/a5gnaXFwVDwXvWo58"
              >
                6F Gopala Tower Rajendra Place 

                <span className="pl-1">
                New Delhi  India - 110008
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      </div>
     

      {/* <div
        class=" mr-36  text-center float-right 
						-mt-11 w-1/3 flex-col rounded-xl 
						bg-white text-gray-700 shadow-2xl"
      >
        <div className="p-6 ">
          <h5
            className="mb-2 block font-sans 
								text-xl font-semibold 
								text-blue-gray-900 antialiased"
          >
            Contact details
          </h5>
          <i className="fa fa-handshake fa-2xl"></i>
          <div className="text-left mt-4">
    
            <span>Please get in touch with us by clicking on the contact details listed on the left or fill in the form given below for any query.</span>
          </div>
        
        </div>
      </div> */}
    </div>
  );
}
