import React, { useEffect } from 'react'
import Header from '../Header'
import PackageCard from '../PackageCard'

export default function Vietnam() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
    const data = [
        {
          title: "HANOI-HA LONG- NINH BINH- HUONG PAGODA",
          img: "https://lp-cms-production.imgix.net/2021-04/shutterstockRF_382969822.jpg?auto=format&fit=crop&sharp=10&vib=20&ixlib=react-8.6.4&w=850&q=20&dpr=5",
          interval: "6 days / 5 Nights",
          price: "₹45,000",
          readMore: "/chardham-package",
          description: {
            about:
               "Empty",
            departure: " Airport",
            departureTime: "6:00 pm",
          },
          timeline: [
            {
              id: 1,
              day: "Day 1",
              place: "Welcome to Hanoi City",
              about:
                "Upon arrival at Hanoi airport, the driver will pick you up from your hotel in Hanoi Old Quarter. Early check-in for 1-2 hours and then visit Hanoi. Join the tour.",
            },
            {
              id: 2,
              day: "Day 2",
              place: "Hanoi - Halong Bay",
              about:
                "Have breakfast and then head to Ha Long Bay.",
            },
            {
              id: 3,
              day: "Day 3",
              place: "Halong Bay - Hanoi",
              about:
                "In the morning, practice Tai Chi on the deck of the Junk boat. Enjoy an early morning swim and kayak around lagoons and islets. Check out of the cabin, enjoy a full brunch buffet, and cruise back to port. Disembark around midday for your return drive to Hanoi. Check-in at the hotel. Overnight in Hanoi.",
            },
            {
              id: 4,
              day: "Day 4",
              place: "Ninh Binh - Bai Dinh - Trang An - Hang Mua",
              about:
                "Our guide will meet you at your hotel/stay and start a 110km modern bus journey through the verdant landscape of the countryside. A short break during the route. Join the tour.",
            },
            {
              id: 5,
              day: "Day 5",
              place: "Huong Pagoda",
              about:
                "Pick up at your hotel at 8:30 AM. Spend about two hours driving through the picturesque countryside and back roads to the village of Du. Join the tour.",
            },
            {
              id: 6,
              day: "Day 6",
              place: "Hanoi - Flight Home",
              about:
                "After breakfast, go to the airport for the flight home.",
            },
          ],
        },
        {
          title: "HANOI-HA LONG- NINH BINH- SAPA",
          img: "https://cdn.tourradar.com/s3/serp/original/5032_Gia44gKW.jpg",
          interval: "6 days / 5 Nights",
          price: "₹59,000",
          description: {
            about:
               "Empty",
            departure:" Airport",
            departureTime: "6:00 pm",
          },
          timeline: [
            {
              id: 1,
              day: "Day 1",
              place: "Welcome to Hanoi City",
              about:
                "Upon arrival at Hanoi airport, the driver will pick you up from your hotel in Hanoi Old Quarter. Early check-in for 1-2 hours and then visit Hanoi. Join the tour.",
            },
            {
              id: 2,
              day: "Day 2",
              place: "Ha Long Bay Cruise",
              about:
                "After breakfast, head to Ha Long Bay. Board a traditional Vietnamese Junk Boat for a cruise. Explore Sung Sot Cave and cruise through the bay's islands and floating villages. Enjoy leisure time for spa activities or swimming. Experience the chef’s cooking demonstration and sunset on the top deck. Overnight on Cruise in Ha Long Bay.",
            },
            {
              id: 3,
              day: "Day 3",
              place: "Ha Long Bay - Ha Noi",
              about:
                "Start the day with Tai Chi on the Junk boat's deck. Swim at one of Halong Bay’s beaches and take a kayak ride. Enjoy a brunch buffet as you cruise back to port. Disembark around midday for the return drive to Hanoi. Check-in at the hotel and overnight in Hanoi.",
            },
            {
              id: 4,
              day: "Day 4",
              place: "Ninh Binh - Bai Dinh - Trang An - Hang Mua",
              about:
                "Join a tour to Ninh Binh. Visit Bai Dinh pagoda complexes and have lunch. Explore the Trang An Grottoes on a traditional sampan. Visit Mua Cave and trek to the top of Ngoa long mountain. Return to Hanoi and overnight stay.",
            },
            {
              id: 5,
              day: "Day 5",
              place: "Sapa - Cat Cat - Fansipan",
              about:
                "Upon arrival in Sapa, check-in at the hotel and have breakfast. Relax until 10:00, then visit Fansipan Mountain via cable car. Enjoy the view from the highest point. In the afternoon, explore the village of Lao Chai, inhabited by Black H’mong people. Return to Sapa and take a bus back to Hanoi.",
            },
            {
              id: 6,
              day: "Day 6",
              place: "Ha Noi - Flight Home",
              about:
                "Upon returning to Hanoi, early check-in. Have breakfast and free shopping in Hanoi. Then go to the airport for the flight home.",
            },
          ],
        },
    ]
  return (
    <div>
        <Header/>
         <h1>Vietnam</h1>
         <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </div>
  )
}
