import React from 'react'

export default function Videoo(props) {
  return (
    <>
       <div class={`carousel-item ${props.i && props.i==='0'? "active":""}`}>
     <h2 className='text-red-500 font-bold'>{props.title}</h2>
    <video ref={props.refs} id="myvideo" controls style={{border:'5px solid black'}} className='w-[250px] max-h-[400px]  rounded-3xl md:w-[300px] md: h-[500px]   '>
       <source src={props.source} type="video/mp4" />
     </video>
    </div>
    </>
  )
}
