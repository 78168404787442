import React, { useEffect } from 'react'
import PackageCard from "../PackageCard";
import Header from "../Header";

export default function HimachalPackage() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
  const data = [
    {
      title: "Dharamshala DalHousie",
      img: "https://www.holidest.com/images/indiatours/himachal/dharamshala-4.jpg",
      interval: "6 days / 5 Nights",
      price: "₹15,000",
      readMore: "/chardham-package",
      description: {
        about:
           "Empty",
        departure: "Pathankot",
        departureTime: "6:00 pm",
      },
      timeline: [
        {
          place: "Delhi – Dharamshala",
          about: "Late night, our driver will pick you up at your spot, and then there will be an overnight drive from Delhi to Dharamshala.",
        },
        {
          place: "Dharamshala",
          about: "Check in at the hotel upon arrival in the morning and spend some time exploring Dharamshala. The hill town is immersed in Tibetan culture and is known as the heavenly residence of the Dalai Lama. Overnight stay in Dharamshala.",
        },
        {
          place: "Dharamshala (McLeod Ganj)",
          about: "Day 3 comes in with a chance to visit Dharamshala's top attractions, including Dal Lake, Shiva Temple, St. John's Church, Tibetan Monastery, and Bhagsu Nag Temple. Travelers can enjoy shopping, try different dishes, or just relax. Night stay in Dharamshala.",
        },
        {
          place: "Dharamshala - Dalhousie (120 km/ 4 hours approx.)",
          about: "On day 4, you will be experiencing the charm of Dalhousie. The route you will be following to reach the destination is filled with greenery and a refreshing vibe. As soon as you reach Dalhousie, check in at the hotel and unwind for the day. Later, if you wish to, you can go for an excursion around. Overnight stay in Dalhousie.",
        },
        {
          place: "Dalhousie",
          about: "On day 5 of your Dharamshala Dalhousie Mcleodganj tour, prepare for a sightseeing tour after a delectable breakfast. Dalhousie makes its name as one of the top hill stations in Himachal Pradesh. The attraction was founded and named after Lord Dalhousie to serve as a summer retreat. One can visit Chamunda Devi Temple, Satdhara Falls, and Kalatop Sanctuary. The rest of the evening is free for leisure activities. Night stay in Dalhousie.",
        },
        {
          place: "Departure from Dalhousie to Dehi",
          about: "Today is the last day of your tour, so pack all the wonderful memories and check out from the hotel. You will be escorted to the Delhi railway station for your journey ahead.",
        },
      ],
    },
    {
      title: "Dharamshala ",
      img: "https://www.holidest.com/images/indiatours/himachal/dharamshala-4.jpg",
      interval: "5 days / 4 Nights",
      price: "₹12,500",
      readMore: "/chardham-package",
      description: {
        about:
           "Empty",
        departure: "Pathankot",
        departureTime: "6:00 pm",
      },
      timeline:  [
        {
          place: "Delhi – Dharamshala (Overnight)",
          about: "Late night, our driver will pick you up at your spot, and then there will be an overnight drive from Delhi to Dharamshala.",
        },
        {
          place: "Dharamshala",
          about: "Check in at the hotel upon arrival in the morning and spend some time exploring Dharamshala. The hill town is immersed in Tibetan culture and is known as the heavenly residence of the Dalai Lama. Overnight stay in Dharamshala.",
        },
        {
          place: "Dharamshala (McLeod Ganj)",
          about: "Day 2 comes in with a chance to visit Dharamshala's top attractions, including Dal Lake, Shiva Temple, St. John's Church, Tibetan Monastery, and Bhagsu Nag Temple. Travelers can enjoy shopping, try different dishes, or just relax. Night stay in Dharamshala.",
        },
        {
          place: "Dharamshala - Delhi",
          about: "Today is the last day of your tour, so pack all the wonderful memories and check out from the hotel. You will be escorted to the Yamunanagar railway station for your journey ahead.",
        },
      ],
    },
    {
      title: "Shimla Manali ex-Chandigarh",
      img: "https://wildtrekholidays.in/wp-content/uploads/2020/03/Shimla-Manali-2.jpg",
      interval: "6 days / 5 Nights",
      price: "₹22,500",
      readMore: "/chardham-package",
      description: {
        about:
        "Empty",
        departure: "Chandigarh",
        departureTime: "6:00 pm",
      },
      timeline:  [
        {
          day: "Day 1",
          place:'Chandigarh Railway Station to Shimla',
          about: "Transport from Chandigarh Railway Station to Shimla for hotel check-in.",
        },
        {
          day: "Day 2",
          place:'Tour visiting',
          about: "Tour visiting IIAS, Sankat Mochan Temple, and Jakhu Temple. Afternoon visit to Kufri.",
        },
        {
          day: "Day 3",
          place:'On the Way to Manali',
          about: "On the way to Manali, visit Kullu town, Rafting in the Beas River, Devi Temple, and the famous Kullu Shawl Industry. Also, visit Sunder Lake or Pandoh Dam. On arrival, check-in to Manali. Overnight stay at Hotel.",
        },
        {
          day: "Day 4",
          place:'Visist City Tour',
          about: "Visit city tour (Mall Road Market, Hadimba Devi Temple, Club House, Manu Temple, Van Vihar, Tibetan Monastery & Vashist Bath Hot Spring Water).",
        },
        {
          day: "Day 5",
          place:'Tour visit Solang Valley',
          about: "After Breakfast, tour visit Solang Valley.",
        },
        {
          day: "Day 6",
          place:'Check out',
          about: "After breakfast, check out from the hotel and drive to Chandigarh. After reaching the airport or railway station, proceed to your onward destination.",
        },
      ],
    },
    {
      title: "Shimla",
      img: "https://www.tourmyindia.com/socialimg/himachal-tour-packages.jpg",
      interval: "3 days / 2 Nights",
      price: "7,100",
      readMore: "/chardham-package",
      description: {
        about:
          "Empty",
          departure: "Shimla",
          departureTime: "6:00 pm",
      },
      timeline:[
        {
          day: "Day 01 (21/February/2022)",
          place: "Shimla - Local Sightseeing - Kufri",
          about: "Today, reach Shimla, check into the hotel, take some rest, and after freshening up, visit Kufri. Enjoy Adventure Resort, horse riding, yak riding, and on the way visit Hassan Valley, Charabara Valley, WildFlower Hall. By late afternoon, return to Shimla and free to stroll Shimla's famous market places like Mall Road, Ridge, Christ Church, Scandal Point, Kali Bari Temple, Lakkar Bazzar, The Heritage Museum, State Library, Lower Bazar. In the evening, back to the hotel for overnight stay. (Meal Plan: Dinner Only)",
        },
        {
          day: "Day 02 (22/February/2022)",
          place: "Shimla-Manikaran Kasol - Sightseeing (233 Km 7/8 Hours)",
          about: "After enjoying breakfast, check out from the hotel, and depart from Shimla to Manikaran Kasol via Mandi. On the way, visit Sunder Nagar, Hanogi Mata Temple, and other sightseeing places. Overnight stay at Kasol Hotel. (Meal Plan: Breakfast & Dinner)",
        },
        {
          day: "Day 03 (23/February/2022)",
          place: "Checkout & Tour End",
          about: "Today morning after breakfast, check out of the hotel. You can do Manikaran Kasol local sightseeing by yourself. Tour ends.",
        },
      ],
    },
  ];
  return (
    <>
      <Header />
      <h1>Himachal</h1>

      <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </>
  );
}
