import React, { useEffect } from 'react'
import Header from '../Header'
import PackageCard from '../PackageCard'
export default function Thailand() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
    const data = [
        {
          title: "2N Pattaya, 1N Bangkok",
          img: "https://www.madmonkeyhostels.com/wp-content/uploads/2015/11/shutterstock_701150233.jpg",
          interval: "4 days / 3 Nights",
          price: "₹17,000",
          readMore: "/chardham-package",
          description: {
            about:
               "Empty",
            departure: " Airport",
            departureTime: "6:00 pm",
          },
          timeline:  [
            {
              id: 1,
              day: "Day 1",
              place: "PickUp",
              about:
                "PickUp"
            },
            {
              id: 2,
              day: "Day 2",
              place: "Coral islana and alcazar show",
              about:
                "Coral islana and alcazar show",
            },
            {
              id: 3,
              day: "Day 3",
              place: "Transfer Bangkok and city tour",
              about:
                "Transfer Bangkok and city tour",
            },
            {
              id: 4,
              day: "Day 4",
              place: "Drop airport",
              about:
                "Drop airport",
            }],
        },
    ]
  return (
    <div>
        <Header/>
         <h1>Thailand</h1>
         <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </div>
  )
}
