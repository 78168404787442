import React, { useEffect } from 'react'
import Header from '../Header'
import PackageCard from '../PackageCard'
export default function Singapore() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
    const data = [
        {
          title: "Singapore",
          img: "https://mediaim.expedia.com/localexpert/1378878/a052e968-fd05-40be-87f8-3b9c966c6da2.jpg",
          interval: "5 days / 4 Nights",
          price: "₹35,000",
          readMore: "/chardham-package",
          description: {
            about:
               "Empty",
            departure: " Airport",
            departureTime: "6:00 pm",
          },
          timeline: [
            {
              day: "Day 1",
              place: "Arrive Singapore",
              about: "On arrival from the airport, you are transferred to your hotel. Check-in at the hotel and relax till evening. In the evening, get ready for an amazing Night Safari (world’s first nocturnal zoo) on the outskirts of Singapore city. Return back to the hotel late evening. Overnight stay in Singapore.",
              themes: ["Adventure"],
            },
            {
              day: "Day 2",
              place: "Singapore City Tour and Sentosa Island",
              about: "After breakfast, get ready to experience the mega metropolis of Singapore. Visit the Colonial district, Little India, places of worship, China Town, Mount Faber that offers excellent views of the Singapore skyline. Later in the afternoon, get ready to explore Sentosa Island: You will be visiting Under Water World, Digital Photo, Images of Singapore, Merlion Tower, and Songs of the Sea show. Later in the evening, you are dropped back to the hotel. Overnight stay in the hotel.",
              themes: ["Adventure"],
            },
            {
              day: "Day 3",
              place: "Singapore - Full day Universal Studios",
              about: "After breakfast in the morning, enjoy your trip to Universal Studios. Enter the world of Hollywood at Universal Studios Singapore and ride the movies on roller coasters and other themed attractions. You can also see the dinosaurs in the Jurassic Park Adventure, plunge into an equatorial rainforest in Madagascar, and meet mummies from ancient Egypt. It’s a day of thrills. Later, you are dropped back to the hotel for your stay in Singapore.",
              themes: ["Adventure", "Beaches and Islands"],
            },
            {
              day: "Day 4",
              place: "Singapore",
              about: "In the morning after breakfast, you are free to explore the vibrant Malls of Singapore for shopping. In the evening, get ready to visit Gardens by the Bay with a Light & Sound show. Overnight stay in the hotel.",
              themes: ["Adventure", "Beaches and Islands"],
            },
            {
              day: "Day 5",
              place: "Departure - Singapore",
              about: "After breakfast, check out from the hotel. You will be driven down to Singapore International Airport to board the flight back home.",
            },
          ],
        },
    ]
  return (
    <div>
        <Header/>
         <h1> Singapore</h1>
         <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </div>
  )
}
