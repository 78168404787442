import React from "react";
import Header from "./Header";
import Card from "./Card";
import img1 from "./TouristVisaimg/ArmeniaVisa.jpeg";
import img2 from "./TouristVisaimg/Azerbaijan.jpeg";
import img3 from "./TouristVisaimg/CanadaVisa.jpeg";
import img4 from "./TouristVisaimg/CombodiaVisa.jpeg";
import img5 from "./TouristVisaimg/EuropeVisa.jpeg";
import img6 from "./TouristVisaimg/KashmirVisa.jpeg";
import img7 from "./TouristVisaimg/MalaysiaVisa.jpeg";
import img8 from "./TouristVisaimg/NewZealandVisa.jpeg";
import img9 from "./TouristVisaimg/SingaporeVisa.jpeg";
import img10 from "./TouristVisaimg/SriLankaVisa.jpeg";
import img11 from "./TouristVisaimg/ThailandVisa.jpeg";
import img12 from "./TouristVisaimg/TurkeyVisa.jpeg";
import img13 from "./TouristVisaimg/UzbekistanVisa.jpeg";
import img14 from "./TouristVisaimg/VietnamVisa.jpeg";
import img15 from "./TouristVisaimg/DubaiVisa.jpeg";

import combodia from "./TourVisaDoc/Combodia.pdf";
 import dubai from "./TourVisaDoc/Dubai.pdf";
 import Malaysia from "./TourVisaDoc/Malaysia.pdf";
 import Singapore from "./TourVisaDoc/Singapore.pdf";
 import Thai from "./TourVisaDoc/Thailand.pdf";
  import Turkey from "./TourVisaDoc/Turkey.pdf";
   import Uzbekistan from "./TourVisaDoc/Uzbekistan.pdf";
 import Vietnam from "./TourVisaDoc/Vietnam.pdf";

export default function TouristVisa() {
  const touristVisa = [
    { title: "Armenia", url: img1 ,doc:combodia },
    { title: "Azerbaijan", url: img2 ,doc:combodia },
    { title: "Canada", url: img3,doc:combodia  },
    { title: "Combodia", url: img4,doc:combodia },
    { title: "Europe", url: img5,doc:combodia  },
    { title: "Malaysia", url: img7 ,doc:Malaysia },
    { title: "NewZealand", url: img8 ,doc:combodia },
    { title: "Singapore", url: img9,doc:Singapore  },
    { title: "SriLanka", url: img10 ,doc:combodia },
    { title: "Thailand", url: img11 ,doc:Thai},
    { title: "Turkey", url: img12 ,doc:Turkey },
    { title: "Uzbekistan", url: img13 ,doc:Uzbekistan },
    { title: "Vietnam", url: img14 ,doc:Vietnam },
    { title: "Dubai", url: img15 ,doc:dubai },
  ];
  return (
    <>
      <Header />
      <h1>Toursit Visa</h1>
      <div className="container my-3">
        <div className="row" style={{ marginTop: "50px" }}>
          <div className="container">
            <div className="row">
              {touristVisa.map((element, i) => {
                return (
                  <div className="col-md-4" key={i}>
                    <Card img={element.url} title={element.title} doc={element.doc} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
