import React from 'react'

export default function Card(props) {
  return (
 
      
      <div className='container my-3 d-flex' style={{maxWidth:'350px',maxHeight:'600px'}}>
      <div className="card d-flex" >
  <img src={props.img}  className="card-img-top d-flex" alt="..."/>
  <div className="card-body">
    <h2 className="card-title">{props.title}</h2>
    {/* <p className="card-text">desc</p> */}
  { props.doc!==""? <a href={ props.doc}target="_blank" className="btn btm-sm  btn-primary ">Required Doc</a>:""}
  </div>
</div>
      </div>
  
  )
}
