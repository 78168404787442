import React from 'react'

export default function Accordion(props) {
    const{value,item}=props
  return (
    <>
      <div class="accordion-item">
    <h1 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${value}`} aria-expanded="false" aria-controls={`flush-collapse${value}`}>
       <h3>
       <h2 className='btn btn-outline-dark mx-3' style={{minWidth:'80px'}}>Day-{value+1} </h2>
      </h3>
     <h3 style={{marginTop:'-15px',}}> {item.place}</h3> 
        
      </button>
    </h1>
    <div id={`flush-collapse${value}`} class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div>    <i class="fa-solid fa-location-dot"></i> Location</div>
        <div className='my-2' style={{justifyContent:'left',textAlign:'left'}}><h4>{item.about}</h4></div>
        {item.flyingTime?
         <table>
         <tr>
           <td rowspan="2" style={{backgroundColor:'lightgrey'}}>Flying Time {item.flyingTime}</td>
           <td style={{backgroundColor:'black',color:'white'}}>{item.departure}</td>
         </tr>
         <tr style={{backgroundColor:'black',color:'white'}}>
           <td>{item.arrival}</td>
         </tr>
       </table>:""
        }
         </div>
    </div>
  </div>
    </>
  )
}
