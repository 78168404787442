import './App.css';
import HomePage from './components/HomePage';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom'; 
import Visa from './components/Visa';
import Header from './components/Header';
import InternationalTour from './components/InternationalTour';
import TouristVisa from './components/TouristVisa';

import { useEffect, useRef, useState } from 'react';
import ChardhamPackages from './components/DomesticPackagespage/ChardhamPackages';
import HimachalPackage from './components/DomesticPackagespage/HimachalPackage';
import UttrakhandPackage from './components/DomesticPackagespage/UttrakhandPackage';
import RajasthanPackage from './components/DomesticPackagespage/RajasthanPackage';
import GoaPackage from './components/DomesticPackagespage/GoaPackage';
import KeralaPackage from './components/DomesticPackagespage/KeralaPackage';
import OotPackage from './components/DomesticPackagespage/OotPackage';
import AnadamanPackage from './components/DomesticPackagespage/AnadamanPackage';
import Northpackage from './components/DomesticPackagespage/Northpackage';
import Kashmir from './components/DomesticPackagespage/Kashmir';
import Leh from './components/DomesticPackagespage/Leh';
import WorkVisa from './components/WorkVisa';
import ContactUs from './components/ContactUs/ContactUs';
import PackageInfo from './components/packageinfo/PackageInfo';
import InternationslFlight from './components/Flight/InternationslFlight';
import DomesticFlight from './components/Flight/DomesticFlight';
import ClientReview from './components/ClientReview';
import Dubai from './components/InternationalPackagesPage/Dubai';
import Singapore from './components/InternationalPackagesPage/Singapore';
import Malaysia from './components/InternationalPackagesPage/Malaysia';
import Thailand from './components/InternationalPackagesPage/Thailand';
import Bali from './components/InternationalPackagesPage/Bali';
import Vietnam from './components/InternationalPackagesPage/Vietnam';
import Maldives from './components/InternationalPackagesPage/Maldives';
import SriLanka from './components/InternationalPackagesPage/SriLanka';
function App() {

  return (
    <div className="App">
    
 <Router>
  <Routes>
    <Route path='/' element={ <HomePage/>}/>
    <Route path='/header' element={ <Header/>}/>
    {/* FLIGHTS */}
    <Route path='/international-flight' element={ <InternationslFlight/>}/>
    <Route path='/domestic-flight' element={ <DomesticFlight/>}/>
    {/* VISA */}
    <Route path='/tourist-visa' element={ <TouristVisa/>}/>
    <Route path='/work-visa' element={ <WorkVisa/>}/>
    {/* DOMESTIC TOUR */}
    <Route path='/chardham-packages' element={ <ChardhamPackages/>}/>
    <Route path='/himachal-packages' element={ <HimachalPackage/>}/>
    <Route path='/uttrakhand-packages' element={ <UttrakhandPackage/>}/>
    <Route path='/rajasthan-packages' element={ <RajasthanPackage/>}/>
    <Route path='/goa-packages' element={ <GoaPackage/>}/>
    <Route path='/kerala-packages' element={ <KeralaPackage/>}/>
    {/* <Route path='/ooty-packages' element={ <OotPackage/>}/> */}
    <Route path='/andaman-packages' element={ <AnadamanPackage/>}/>
    <Route path='/north-packages' element={ <Northpackage/>}/>
    <Route path='/kashmir-packages' element={ <Kashmir/>}/>
    <Route path='/leh-packages' element={ <Leh/>}/>
    {/* PACKAGES INFO-> CONTAIN ITERNAY */}
    <Route path='/package-info' element={ <PackageInfo/>}/>
    {/* INTERNATIONAL PACKAGES */}
    <Route path='/dubai-packages' element={ <Dubai/>}/>
    <Route path='/singapore-packages' element={ <Singapore/>}/>
    <Route path='/malaysia-packages' element={ <Malaysia/>}/>
    <Route path='/thailand-packages' element={ <Thailand/>}/>
    <Route path='/bali-packages' element={ <Bali/>}/>
    <Route path='/vietnam-packages' element={ <Vietnam/>}/>
    <Route path='/maldives-packages' element={ <Maldives/>}/>
    <Route path='/srilanka-packages' element={ <SriLanka/>}/>
    {/* CONTACT US */}
    <Route path='/contact-us' element={ <ContactUs/>}/>
    {/* CLIENT REVIEW */}
    <Route path='/client-reviews' element={ <ClientReview/>}/>
  </Routes>
 </Router>   
    </div>
  );
}

export default App;
