import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { useLocation } from 'react-router-dom'
import Option from './Option';
import ContactForm from '../ContactForm';


export default function PackageInfo() {
    const location=useLocation();
    const [btn,setBtn]=useState(1)
    const[data,setData]=useState("")
    useEffect(()=>{
        window.scrollTo(0, 0);
        setData(location.state);
        console.log(data)
    },[])
  return (
    < >
    {
      data===""?"":<>
      <Header/>
    <h1 style={{color:'green',textAlign:'center'}}>{data.title}</h1>

<div className='okcontainer'>
  <div className='imagediv'>

    <img className='imagee' src={data.img}  alt="..."></img>
  </div>
 
    <div className='myform'>
    <div className='formcontains'>
			<ContactForm title={"Book This Tour"} category={`Booking of ${data.title}`}/>
    </div>
    </div>

    </div>
  <Option data={data}/>
      </>
    }
    </>
  )
}
