import React from 'react'
import'./About.css'
import himanshu from './images/himanshu1.jpeg'
export default function About() {
  return (
    <>
       <section id="about" class="about">
        <h4>About Us</h4>
        <p>
          We are dedicated to providing the best travel experiences to
          our customers. We specialize in creating custom itineraries that cater
          to each individual's interests and preferences.
        </p>
        <div class="team-members">
          <div class="team-member">
            <img src={himanshu} alt="" />
            <h4>Himanshu</h4>
            <p>Founder & CEO</p>
          </div>
          
        </div>
      </section>
    </>
  )
}
