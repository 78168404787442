import Header from "../Header";
import Card from "./Card";
import Feedback from "./Feedback";
import Icons from "./Icons";
import Welcome from "./Welcome";
import "./ContactUs.css";
import map from "../images/map.png";
function ContactUs() {
  return (
    <>
      <Header />
      <Welcome />
      <Icons />
      <Card />
      <div className=" flex flex-col  lg:flex-row justify-evenly md:mt-8">
        <div className="flex justify-center mt-4">

      <Feedback />
        </div>
     <a href="https://maps.app.goo.gl/BM29s7vmi91Qd7Sm9"> <img
        src={map}
        className=" w-[400px] md:w-[700px] mt-10  "
      
      /></a>
      </div>
    </>
  );
}

export default ContactUs;
