import React, { useEffect } from 'react'
import PackageCard from '../PackageCard'
import Header from '../Header'


export default function UttrakhandPackage() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
  const data = [
    {
      title: " Camping Shivpuri(Rishikesh)",
      img: "https://images.thrillophilia.com/image/upload/s--OYhCvxex--/c_fill,f_auto,fl_strip_profile,h_800,q_auto,w_1300/v1/images/photos/000/044/516/original/1521701328_078B8855.jpg.jpg?1521701325",
      interval: "2 days / 1 Night",
      price: "₹15,000",
      description: {
        about:
           "Empty",
        departure: "Railway Station",
        departureTime: "6:00 pm",
      },
      info: [
        {
          about: "Inclusion"
        },
        {
          data: "⏱ 01 Night / 02 Days"
        },
        {
          data: "⛺ Accommodation in Deluxe cottage"
        },
        {
          data: "Cooler Available in cottage"
        },
        {
          data: "🚽 Attached washroom in cottage"
        },
        {
          data: "🍲 1 Buffet Lunch (Veg.)"
        },
        {
          data: "🥘 1 Buffet Dinner (Veg./Non-Veg.)"
        },
        {
          data: "🥪 1 Buffet Breakfast"
        },
        {
          data: "🍔 Veg Evening snacks"
        },
        {
          data: "☕ Morning & Evening tea"
        },
        {
          about: "Activities:",
          
        },

        {
          data: "🏏 Badminton, Carram, etc.",
          
        },
        {
          data:   "🏊🏻 Swimming Pool",
          
        },
        {
          data:  "🎼 DJ Music till 10:00 PM"
          
        },
      ],
    },
    {
      title: "Spirtual tour",
      img: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1a/44/2f/7b/caption.jpg?w=1200&h=1200&s=1",
      interval: "2 days / 1 Night",
      price: "₹5,000",
      description: {
        about:
           "Empty",
        departure: "Railway Station",
        departureTime: "6:00 pm",
      },
      timeline:  [
        {
          day: "Day 1",
          place: "Delhi to Haridwar (222 km / approx 5 hours)",
          about:
            "Pick up from Delhi airport or railway station\n" +
            ",Start your drive to Haridwar (recommended to start early)\n" +
            ",Reach Haridwar by afternoon\n" +
            ",Visit Shantikunj Ashram, Pawan Dham, Mansa Devi, Chandi Devi Temple, and Daksh Temple\n" +
            ",Later, visit Har ki Pouri (Ganga Ghat) to be a part of Ganga Aarti\n" +
            ",Dinner and overnight stay in Haridwar"
        },
        {
          day: "Day 2",
          place: "Haridwar to Rishikesh",
          about:
            "After breakfast drive to Rishikesh\n" +
            ",Visit Lakshman Jhula, Ram Jhula, Swarg Ashram, Bharat Mandir\n" +
            ",Overnight stay in Rishikesh"
        }
      ],
    },
    {
      title: "Explore Uttarkhand",
      img: "https://static.toiimg.com/photo/66150788/Main.jpg?width=748&resize=4",
      interval: "7 days / 6 Nights",
      price: "₹18,000",
      description: {
        about:
           "Empty",
        departure: "Railway Station",
        departureTime: "6:00 pm",
      },
      timeline:[
        {
          day: 1,
          place: "Delhi to Haridwar (222 km / approx 5 hours)",
          about:
            "Pick up from Delhi airport or railway station. Start your drive to Haridwar (recommended to start early). Reach Haridwar by afternoon. Visit Shantikunj Ashram, Pawan Dham, Mansa Devi, Chandi Devi Temple and Daksh Temple. Later, visit Har ki Pouri (Ganga Ghat) to be a part of Ganga Aarti. Dinner and overnight stay in Haridwar."
        },
        {
          day: 2,
          place: "Haridwar to Rishikesh to Dehradun",
          about:
            "After breakfast, drive to Dehradun. En route, visit Lakshman Jhula, Ram Jhula, Swarg Ashram, Bharat Mandir. On arrival to Dehradun, places to visit include FRI, Tapkeshwar Temple, Dear Park and Shiv Ratankander, Bazar Paltan Bazar. Explore Indian Military Academy, RIMC (Rashtriya Indian Military College), Indira Gandhi National Forest Academy (IGNFA), Lal Bahadur Shahstri National Academy of Administration (LBSNAA). GucchuPani aka Robbers' Cave and Sahastradhara are another popular tourist spot in Dehradun. Later, check-in to the hotel. Overnight stay in Dehradun."
        },
        {
          day: 3,
          place: "Dehradun to Mussoorie (35 km, 2 hours)",
          about:
            "On arrival at the airport, my representative will meet you and drive you to Mussoorie. On arrival, visit Kempty Falls, Tibetan Buddhist Temple, Mallroad, Lal Tibba, Camel’s Back Road. Then, head towards Hathipaon at cloud’s end, George Everest’s House, Christ Church and Municipal Garden. Overnight stay in Mussoorie."
        },
        {
          day: 4,
          place: "Mussoorie to Jim Corbett (200 km, 6 hours)",
          about:
            "After breakfast, head for sightseeing. Explore Mussoorie and drive to Jim Corbett. Reach Corbett by evening. Visit Garjiya Devi Temple, Sitabani Temple and Corbett Falls. Overnight stay in Corbett."
        },
        {
          day: 5,
          place: "Jim Corbett to Nainital",
          about:
            "After breakfast, our representative will drive you to the nearest elephant safari riders (on direct payment). Enjoy Corbett jungle safari which should be pre-booked (INR 4500 Private Jeep). Leave for Nainital and reach by evening. Visit Naina Devi Temple and Eco Cave Garden. Indulge yourself to shop the beautiful varieties at the mall road. Drive to the hotel. Overnight stay in Nainital."
        },
        {
          day: 6,
          place: "Nainital Sightseeing",
          about:
            "After breakfast, prepare yourself to enjoy the beauty and activities of the lake city. Indulge yourself into boating at Nainital Lake (own cost). Enjoy the most hilarious horse riding (own cost) to reach the Tiffin top, ride the rope way (own cost) to watch the Snow Point and Naini Peak. In the evening, return to the hotel. Dinner and overnight stay in Nainital."
        },
        {
          day: 7,
          place: "Nainital to Delhi (300 km, 7 hours)",
          about:
            "After breakfast, drive to Delhi. Reach Delhi by late evening. Drop to the airport for onward journey."
        }
      ],
    },
]
  return (
    <>
    <Header/>
    <h1>Uttrakhand</h1>
    <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </>
  )
}
