import React, { useEffect, useState } from "react";
import logo from "./images/logo.png";
import "./Navbar.css";
export default function Navbar() {

  useEffect(() => {
    const marquee = document.querySelector(".marquee");
    const texts = [
      "Chardham Yatra 2024 Group Departure Deals From Haridwar/Delhi: Departure Date(May & June)",
      "Chardham Yatra 2024 Group Departure Deals From Haridwar/Delhi: Departure Date(May & June)",
    ];


    let currentTextIndex = 0;

    const animateMarquee = () => {
      const text = texts[currentTextIndex];
      const animationDuration = text.length * 0.2; // Adjust speed by changing this value
      marquee.innerHTML = text.repeat(1); // Repeat text for looping effect

      const keyframes = [
        { transform: "translateX(100%)" },
        { transform: "translateX(-100%)" },
      ];

      const options = {
        duration: animationDuration * 1000, // Convert seconds to milliseconds
        iterations: 1, // Run the animation only once for each text
        fill: "forwards", // Keep the final state of the animation
        easing: "linear", // Use a linear easing function
      };

      marquee.animate(keyframes, options).onfinish = () => {
        // Move to the next text in the array
        currentTextIndex = (currentTextIndex + 1) % texts.length;
        // Recursively call the function for the next text
        animateMarquee();
      };
    };

    animateMarquee();
  }, []);
  return (
    <div>
      <div
        className="fixed-top"
        style={{
          marginTop: "92px",
          padding: "5px",
          backgroundColor: "#ded6d6",
          display: "flex",
        }}
      >
        <h1 className="heading" style={{ color: "blue" }}>NEWS:Alert:</h1>
        <div style={{ flex: "1", paddingTop: "10px", overflow: "hidden" }}>
          <h3 style={{ color: "red" }} className="marquee"></h3>
        </div>
      </div>

      <div
        className="fixed-top "
        id='contactbar'
      
      >
        <div
          class="contact-info my-1"
          style={{ marginLeft: "-40px", display: "flex" }}
        >
          <ul>
            <li>
              <a
                style={{ fontSize: "15px", marginRight: "-30px" }}
                href="tel:7015293565"
              >
                <i style={{ fontSize: "15px" }} class="fas fa-phone-alt"></i>
                7015293565
              </a>
            </li>
            <li>
              <a
                style={{ fontSize: "15px" }}
                href="mailto:admiretourism@gmail.com"
              >
                <i style={{ fontSize: "15px" }} class="fas fa-envelope"></i>
                admiretourism@gmail.com
              </a>
            </li>
          </ul>
        </div>
        <div
          class="position-absolute top-0 end-0 my-1"
          style={{ flex: "1", paddingTop: "1px", overflow: "hidden" }}
        >
          <a
            className="mx-3 "
            style={{ color: "black", fontSize: "25px" }}
            href="https://www.facebook.com/people/Admire-Tourism/61552363347302/"
            target="_blank"
          >
            <i class="fab fa-facebook"></i>
          </a>
          <a
            style={{ color: "black", fontSize: "25px" }}
            href="https://www.instagram.com/admire_tourism/"
            target="_blank"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </div>
      </div>

      <nav class="navbar fixed-top  navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="/">
          <img
            width="80"
            height="70"
            class="d-inline-block align-top"
            src={logo}
            alt="logo"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse " id="navbarNav">
        
          <ul class="navbar-nav">
          <li class="nav-item">
              <a class="nav-link" href="/">
                Home
              </a>
            </li>
            {/* <li class="nav-item active">
            <a class="nav-link" href="#about">About <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li> */}
 
            <li className="nav-item dropdown ">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="servicesDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Visa
              </a>
              <div
                className="dropdown-menu flex-column"
                aria-labelledby="servicesDropdown"
              >
                <div className="container ">
                  <a className="dropdown-item" href="/work-visa">
                    Work Visa
                  </a>
                  <a className="dropdown-item" href="/tourist-visa">
                    Tourist Visa
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="servicesDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Domestic tour
              </a>
              <div
                className="dropdown-menu flex-column"
                aria-labelledby="servicesDropdown"
              >
                <div className="container">
                  <a className="dropdown-item" href="/chardham-packages">
                    Chardham Yatra
                  </a>
                  <a className="dropdown-item" href="/himachal-packages">
                    Himachal
                  </a>
                  <a className="dropdown-item" href="/uttrakhand-packages">
                    Uttarakhand
                  </a>
                  <a className="dropdown-item" href="/rajasthan-packages">
                    Rajasthan
                  </a>
                  <a className="dropdown-item" href="/goa-packages">
                    Goa
                  </a>
                  <a className="dropdown-item" href="/kerala-packages">
                    Kerala
                  </a>
                  {/* <a className="dropdown-item" href="/ooty-packages">Ooty</a> */}
                  <a className="dropdown-item" href="/andaman-packages">
                    Andaman Nicobar
                  </a>
                  <a className="dropdown-item" href="/north-packages">
                    North-East
                  </a>
                  <a className="dropdown-item" href="/kashmir-packages">
                    Kashmir
                  </a>
                  <a className="dropdown-item" href="/leh-packages">
                    Leh Ladakh
                  </a>
                </div>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="servicesDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                International Tour
              </a>
              <div
                className="dropdown-menu flex-column "
                aria-labelledby="servicesDropdown"
              >
                <div className="container">
                  <a className="dropdown-item" href="/dubai-packages">
                    Dubai
                  </a>
                  <a className="dropdown-item" href="/singapore-packages">
                    Singapore
                  </a>
                  <a className="dropdown-item" href="/malaysia-packages">
                    Malaysia
                  </a>
                  <a className="dropdown-item" href="/thailand-packages">
                    Thailand
                  </a>
                  <a className="dropdown-item" href="/bali-packages">
                    Bali
                  </a>
                  <a className="dropdown-item" href="/vietnam-packages">
                    Vietnam
                  </a>
                  <a className="dropdown-item" href="/maldives-packages">
                    Maldives
                  </a>
                  <a className="dropdown-item" href="/srilanka-packages">
                    SriLanka
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="servicesDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Flight ✈️
              </a>
              <div
                className="dropdown-menu flex-column"
                aria-labelledby="servicesDropdown"
              >
                <div className="container ">
                  <a className="dropdown-item" href="/domestic-flight">
                    Domestic Flight{" "}
                  </a>
                  <a className="dropdown-item" href="/international-flight">
                    International Flight{" "}
                  </a>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/client-reviews">
               Reviews
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/contact-us">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
