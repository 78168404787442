// Feedback.js 

import ContactForm from "../ContactForm";

export default function Feedback() { 
	return( 
		<div className="
						w-80 float-left border-2 p-2 
						rounded-xl shadow-xl text-lg mb-4 bg-slate-500"> 
			 <ContactForm title={"Feedback & Queries"} category={`Query`}/>
		</div> 
	) 
}
