import React, { useEffect } from 'react'
import Header from '../Header'
import PackageCard from '../PackageCard'
export default function Bali() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
    const data = [
        {
          title: "Bali",
          img: "https://cdn.getyourguide.com/img/location/58bfe50f4b46e.jpeg/88.jpg",
          interval: "4 days / 3 Nights",
          price: "₹34,000",
          readMore: "/chardham-package",
          description: {
            about:
               "Empty",
            departure: "Bali Airport",
            departureTime: "6:00 pm",
          },
          timeline:[
            {
              day: "1st Day ",
              place: "Cab for - Pickup 1-04/6 Seater (Avanza /Xenia) -HD",
              about: "Candle Light Dinner At Jimbaran Beach with Wine (2 Adult)",
            },
            {
              day: "2nd Day ",
              place: "Cab for - Sightseeing 1-04/6 Seater (Avanza /Xenia) - FD14",
              about: "Water Sports 1x Parasailing Adventure, 1x Banana Boat, 1x Jet Ski (2 Adult), Uluwatu Temple Sunset tour (2 Adult)",
            },
            {
              day: "3rd Day ",
              place: "Cab for - Sightseeing 1-04/6 Seater (Avanza /Xenia) - FD14",
              about: "Ubud & Kintamani Kintamani Volcano + Swing + Rice terrace + Waterfall + Monkey Forest + Saraswati Temple (2 Adult)",
            },
            {
              day: "4th Day ",
              place:'Drop',
              about: "Cab for - Drop 1-04/6 Seater (Avanza /Xenia) -HD",
            },
          ],
        },
        {
          title: "Bali",
          img: "https://www.fontica.com/wp-content/uploads/2020/04/Bali-island-Image-scaled.jpg",
          interval: "7 days / 6 Nights",
          price: "₹60,000",
          readMore: "/chardham-package",
          description: {
            about:
               "Empty",
            departure: "Bali Airport",
            departureTime: "6:00 pm",
          },
          info:[
            {
              about: "06 Night's Stay at the above-mentioned hotels with Breakfast",
            },
            {
              about: "Air Conditioned Vehicle during transfers and tours on a private basis",
            },
            {
              about: "Kintamani with Ubud Village + Bali Swing on a Private Basis",
            },
            {
              about: "Uluwatu Sunset Tour with Water sport package with parasailing/fly fish, banana boat & Jet Ski on a Private Basis",
            },
            {
              about: "Inter-Hotel Transfer",
            },
            {
              about: "Ulundanu temple + Handar Gate + Tanah Lot Temple Tour",
            },
            {
              about: "Nusa Penida Island Tour",
            },
            {
              about: "Return Airport Transfers on a Private basis Included",
            },
            {
              about: "Room & Rates are Subject to Availability",
            },
          ],
        },
    ]
  return (
    <div>
        <Header/>
         <h1>Bali</h1> 
         <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
    </div>
  )
}
