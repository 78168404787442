// Welcome.js 



export default function Welcome(){ 
	return( 
		<div className="h-48 bg-gray-200 px-24" style={{marginTop:'-10px'}}> 
			<h1 className="pt-4 text-center text-slate 
						font-semibold text-3xl "> 
				 Contact US Page 
			</h1> 
		</div> 
	) 
}
