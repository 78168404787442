import React, { useEffect, useRef } from 'react';
import './HomePage.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img1 from './images/ch.jpg'
import video from './images/video2.mp4'
import img2 from './images/hero2.jpg'
import img3 from './images/hero3.jpg'
import destination1 from './images/destination-1.jpg'
import destination2 from './images/destination-2.jpg'
import destination3 from './images/destination-3.jpg'
import hotel1 from './images/hotel-1.jpg'
import hotel2 from './images/hotel-2.jpg'
import hotel3 from './images/hotel-3.jpg'
import Navbar from './Navbar';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import InternationalTour from './InternationalTour';
import DomesticTour from './DomesticTour';
import Modal from './Modal';


export default function HomePage() {
  const refOpen = useRef(null);
  const refClose = useRef(null);
  useEffect(()=>{
    refOpen.current.click();
  },[])

  return (
    <div>
        <Modal refOpen={refOpen} refClose={refClose}/>
   <Navbar/>
   <a  href="whatsapp://send?text=Hello World!&phone=+917015293565"><i class="fa-brands fa-square-whatsapp" style={{position:"fixed",bottom:'20px',right:'20px',zIndex:'1000', color:'#4add36',fontSize:'60px'}}></i></a>
    
        <div class="hero">
          <div class="slideshow-container">
          
              {/* <img src={img1} alt="Slide 1" style={{'width':'100%' }}/> */}
              <video  src={video} muted autoPlay loop  type='video/mp4' style={{'width':'100%'}}></video>
     

          </div>
          <div class="hero-content" id="heroContent" >
            <h1  >Welcome to Admire Tourism</h1>
            <h3 style={{color:'black',backgroundColor:'yellow',}}>Discover amazing destinations and book your trip with us.</h3>
            {/* <a href="mailto:admiretourism@gmail.com" class="hero-button">Book Now</a> */}
          </div>
        </div>
             

        <DomesticTour/>
 

       <InternationalTour/>
       

     <About/>
     {/* <Contact/> */}
   <Footer/>
    


    </div>
  );
}