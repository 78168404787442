import React, { useEffect } from 'react'
import PackageCard from "../PackageCard";
import Header from "../Header";

export default function Northpackage() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
  const data = [
    {
      title: "Gangtok - Darjeeling",
      img: "https://www.twinklepatodiya.com/wp-content/uploads/2020/12/WhatsApp-Image-2020-12-16-at-2.57.36-PM-1.jpeg",
      interval: "6 days / 5 Nights",
      price: "₹14,500",
      timeline: [
        {
          day: "Day 1",
          place: "NJP/Bagdogra Airport – Gangtok",
          about: "Arrival at Bagdogra airport/New Jalpaiguri Railway Station. Proceed for Gangtok (5 hrs drive) with an en route view of the Himalayan beauty. Check in to the hotel. Evening walk on M. G. Road. Overnight stay at Gangtok.",
        },
        {
          day: "Day 2",
          place: "Tsomgo Lake + Baba Mandir (Exclude: Nathu La Pass)",
          about: "Early morning excursion to Tsomgo Lake & Baba Mandir. Tsomgo Lake is situated at a height of 12,400 ft and Baba Mandir at 13,200 ft. Return to Gangtok. Evening free for personal activities & shopping at M. G. Marg. Overnight stay at Gangtok.",
          note: "Nathu La Pass visit is conditional and comes with extra cost. Needs to be arranged the previous night. Voters ID card required for Nathu La Pass visit. The booked amount is not refunded if the Nathu La Pass trip gets canceled.",
        },
        {
          day: "Day 3",
          place: "Gangtok local sightseeing",
          about: "Local sightseeing of Tashi View Point, Banjhakri Waterfalls, Ganesh Tak, Bakthang Waterfalls, Flower Show, Cottage Industry, Smriti Park. Evening free for leisure. Overnight stay at Gangtok.",
          note: "Some points may be closed due to unforeseen circumstances.",
        },
        {
          day: "Day 4",
          place: "Gangtok - Darjeeling",
          about: "Transfer to Darjeeling (7,100 ft.). Darjeeling, 'The Queen of the Hills' and 'The Land of the Mystic Thunderbolt.' Check-in at the hotel. Rest of the day at leisure at the Mall. Overnight stay at Darjeeling.",
        },
        {
          day: "Day 5",
          place: "Darjeeling local sightseeing",
          about: "Early morning drive to Tiger Hill to watch the sunrise over Mt. Khangchendzongha. Visit Ghoom Monastery and Batasia Loop on the way back. After breakfast, visit Himalayan Mountaineering Institute, P.N. Zoological Park, Tenzing Rock, Tibetan Refugee self-help Center, Tea Garden (outer view), and Japanese Temple. Return to the hotel. Overnight stay at Darjeeling.",
          note: "Some points may close due to Covid-19.",
        },
        {
          day: "Day 6",
          place: "Darjeeling - NJP/IXB Drop",
          about: "After breakfast, transfer to NJP Railway Station/IXB Airport. Guests are advised to start early and not to rush. Forwarding Journey with sweet memories of the whole trip.",
        },
      ],
      description: {
        about:
           "Empty",
        departure: "NJP Railway Station/IXB Airport",
        departureTime: "9:30",
      },
    },
  ];
  return (
    <>
      <Header />
      <h1>North East</h1>
      <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </>
  );
}
