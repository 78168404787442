import React, { useState } from "react";
import Description from "./Description";
import Timeline from "./Timeline";

import './PackageInfo.css'
export default function Option({data}) {
  const [btn,setBtn]=useState(1)

  return (
    <div style={{marginTop:'10px',paddingBottom:'20px',marginLeft:'20px',marginRight:'20px'}}>
   
    <ul class="nav nav-pills mb-3 "  id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class={` btn btn-${btn===1?'warning':'secondary'} btn-lg `} onClick={()=>setBtn(1)} >Description</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class={`btn btn-${btn===2?'warning':'secondary'} btn-lg mx-3 `} onClick={()=>setBtn(2)} >Tour Timeline</button>
  </li>
 </ul>

 {
  btn===1?<Description data={data}/>:btn===2?<Timeline data={data}/>:'nothing'
 }

 </div>
  );
}
