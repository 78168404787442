import React, { useEffect } from 'react'
import PackageCard from '../PackageCard'
import Header from '../Header'


export default function Leh() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
  return (
    <>
    <Header/>
    <h1>Leh Ladakh</h1>

    <h2 style={{color:"red",marginTop:'20px'}}>
      Coming Soon...
    </h2>

   

    
    </>
  )
}
