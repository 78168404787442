import React, { useEffect } from "react";
import { useState } from "react";
import img from './images/modalimg.jpg'
import ContactForm from "./ContactForm";
export default function Modal(props) {

  return (
    <div>
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        hidden={true}
        data-bs-target="#exampleModal"
        ref={props.refOpen}
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Welcome To Admire Tourism
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body " style={{margin:'-14px'}}>
              <div className="row">
                <div className="col-md-6">
                  <img
                    style={{
                      height: "auto",
                      
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                    src={img}
                    alt=""
                  />
                </div>
                <div className="col-md-6">
                 <ContactForm title={"Booking Your Free Consultation"} category={`Booking of Chardham`}/>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={props.refClose}
                hidden={true}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
