import React, { useEffect } from 'react'
import Header from "../Header";
import PackageCard from "../PackageCard";
export default function Maldives() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
  const data = [
    {
      title: "Maldives",
      img: "https://www.holidify.com/images/bgImages/MALDIVES.jpg",
      interval: "4 days / 3 Nights",
      price: "₹65,000",
      readMore: "/chardham-package",
      description: {
        about: "Empty",
        departure: " Airport",
        departureTime: "6:00 pm",
      },
      info: [
        {
          about: "Stay-1",
        },
        {
          data: "TurquoiseResidencebyUIINN",
        },
        {
          data: "Airport Transfer",
        },
        {
          data: "Meal Plan – Half Board",
        },
        {
          data: "Room Category – 1N Superior Double Room",
        },
        {
          about: "Stay-2",
        },
        {
          data: "Medhufushi Island Resort",
        },

        {
          data: "Mode of Transfer – Speed Boat",
        },
        {
          data: "Meal Plan – All Inclusive",
        },
        {
          data: "Room Category – 2N Water Villa",
        },
      ],
    },
    {
      title: "Maldives",
      img: "https://www.letourdeworld.com/assets/images/places/maldives1.jpg",
      interval: "5 days / 4 Nights",
      price: "₹82,000",
      readMore: "/chardham-package",
      description: {
        about: "Empty",
        departure: " Airport",
        departureTime: "6:00 pm",
      },

      info: [
        {
          about: "  Medhufushi Island Resort",
        },
        {
          data: " Mode of Transfer – Speed Boat",
        },
        {
          data: "Meal Plan – Full Board",
        },
        {
          data: "Meal Plan – Half Board",
        },
        {
          data: "Room Category – 2N Beach Villa + 2N Water Villa",
        },
       
      ],
    },
  ];
  return (
    <div>
      <Header />
      <h1>Maldives</h1>
      <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </div>
  );
}
