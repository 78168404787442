import React from 'react'
import'./Footer.css'
export default function Footer() {
  return (
    <>
         <footer>
        <div class="social-icons">
          <a href="https://www.facebook.com/people/Admire-Tourism/61552363347302/" target="_blank"
            ><i class="fab fa-facebook"></i
          ></a>
          <a href="https://www.instagram.com/admiretourism_/" target="_blank"
            ><i class="fab fa-instagram"></i
          ></a>
        </div>
        <p>&copy; 2023 Admire Tourism Website. All Rights Reserved.</p>
      </footer>
    </>
  )
}
