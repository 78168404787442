import React, { useEffect } from 'react'
import PackageCard from '../PackageCard'
import Header from '../Header'


export default function Kashmir() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
  const data = [
    {
      title: "Kashmir",
      img: "https://www.tripbibo.com/blog/wp-content/uploads/2020/04/kashmir.jpg",
      interval: "6 days / 5 Nights",
      price: "₹11,000",
      readMore: "/chardham-package",
      description: {
        about:
           "Empty",
        departure: "Srinagar",
        departureTime: "6:00 pm",
      },
      timeline:  [
        {
          day: "DAY 01",
          place: "SRINAGAR LOCAL SIGHTSEEING",
          about:
            "Welcome to Srinagar, the Lake City. On arrival at Srinagar Airport, begin your Kashmir Tour. Transfer to the Hotel. Proceed on a city tour visiting the Shankar Acharya Temple, Mughal Gardens (Chashmashahi, Nishat, Shalimar & Tulip Garden - if open), and Hazratbal Dargah. Overnight stay in the hotel.",
        },
        {
          day: "DAY 02",
          place: "SRINAGAR-SONAMARG-SRINAGAR",
          about:
            "Embark on a full day excursion to the picturesque ‘Meadow of Gold’ Sonamarg. Enjoy activities like pony ride (at your own cost) and visit Thajiwas Glaciers. Optionally, visit Zero Point/Zojila Pass (at your own cost). Return to the Hotel in Srinagar in the evening for an overnight stay.",
        },
        {
          day: "DAY 03",
          place: "SRINAGAR-GULMARG",
          about:
            "After breakfast, travel to Gulmarg, known as the ‘Meadow of Flowers.’ Enjoy the world’s highest Golf Course and indulge in winter sports. Optionally, take a Gondola Ride/Cable Car (at your own cost) from Gulmarg to Khilanmarg and visit Mohinishwar Shivalaya Shiv Mandir. Overnight stay at the Gulmarg hotel.",
        },
        {
          day: "DAY 04",
          place: "GULMARG-PAHALGAM",
          about:
            "After breakfast, leave for Pahalgam, the 'Valley of Shepherds.' En route, visit the Awantipura Ruins and Surya Martand Temple. In Pahalgam, check in at the hotel and proceed for local sightseeing, including Aru valley, Betab Valley, and Chandanwari (at your own cost). Overnight stay at Hotel in Pahalgam.",
        },
        {
          day: "DAY 05",
          place: "PAHALGAM-SRINAGAR",
          about:
            "After breakfast, check out from the hotel and proceed for Pony Ride. Visit Kashmir Valley, Dabyan, Baisaran, Denow Valley & Waterfall (at your own cost). Drive to Srinagar. Check in Houseboat. After freshening up, enjoy a Shikara Ride on Dal Lake and return to the Houseboat for an overnight stay.",
        },
        {
          day: "DAY 06",
          place: "RETURN HOME WITH HAPPY MEMORIES",
          about:
            "After breakfast, transfer to Srinagar Airport to board your flight with memorable experiences.",
        },
      ],      
    },
  ]
  return (
    <>
    <Header/>
    <h1>Kashmir</h1>
    <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
   
    
    </>
  )
}
