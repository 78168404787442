import React, { useRef } from 'react'
import Dubai from "../video/Dubai.mp4";
import Dubai2 from "../video/Dubai2.mp4";
import Goa from "../video/Goa.mp4";
import Yatra from "../video/Yatra.mp4";
import Shimla from "../video/Shimla.mp4";
import Videoo from './Videoo';
export default function BotCar() {
  const VideoRef1 = useRef(null);
  const VideoRef2 = useRef(null);
  const VideoRef3 = useRef(null);
  const VideoRef4 = useRef(null);
  const VideoRef5 = useRef(null);
  const stopVideo= ()=>{
    let video=document.getElementById('myvideo')
      video.pause();
      VideoRef5.current.pause()
      VideoRef2.current.pause()
      VideoRef3.current.pause()
      VideoRef4.current.pause()

  }

  return (
    <div >
      <div id="carouselExampleIndicators" class="carousel slide" data-interval="false" data-ride="carousel">

  <div class="carousel-inner">
   <Videoo source={Dubai} title={"Dubai"} i={'0'} />
   <Videoo refs={VideoRef2} source={Yatra} title={"Chardham"}  />
   <Videoo refs={VideoRef3}  source={Shimla} title={"Shimla"}  />
   <Videoo refs={VideoRef4}  source={Dubai2} title={"Dubai"}  />
   <Videoo refs={VideoRef5} source={Goa} title={"Goa"} />
    
  </div>
  <a onClick={stopVideo} class="carousel-control-prev" href="#carouselExampleIndicators"  role="button" data-slide="prev">
    <span style={{backgroundColor:'black'}} class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a onClick={stopVideo} class="carousel-control-next" href="#carouselExampleIndicators"  role="button" data-slide="next">
    <span style={{backgroundColor:'black'}} class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
    </div>
  )
}
