import React from 'react'

export default function Description({data}) {
  return (
    <div class="container" style={{
     
      maxWidth: '700px', 
      backgroundColor: 'white',
      border: '1px solid #ccc',
     
    }} >
      {
        data.description.about!=="Empty"? <div className='my-1'style={{textAlign:'left'}}>
        <h3>
        About {data.title}:
        </h3>
      <p>
                {data.description.about} 
              </p>
      
      </div> :""
      }


      <div className='d-flex align-items-start my-3'>
        <h4>Departure: </h4>
        <p className='mx-1'>{data.description.departure}</p>
      </div>
      <div className='d-flex align-items-start' >
        <h4>Departure Time: </h4>
        <p className='mx-1'>{data.description.departureTime}</p>
      </div>
      <h3>Package Inclusions</h3>
<ul style={{  listStyleType: 'none',textAlign:'left'}} >
    <li><i style={{color:'green'}} class="fa-solid fa-check mx-2"></i> Hotel accommodation as per package same or similar hotels.</li>
    <li><i style={{color:'green'}} class="fa-solid fa-check mx-2"></i> Breakfast</li>
    <li><i style={{color:'green'}} class="fa-solid fa-check mx-2"></i> Transfers and sightseeing as per package</li>
    <li><i style={{color:'green'}} class="fa-solid fa-check mx-2"></i> All toll tax, parking, fuel and driver allowances</li>
    <li><i style={{color:'green'}} class="fa-solid fa-check mx-2"></i> All applicable hotel and transport taxes.</li>
</ul>

<h3>Package Exclusions</h3>
<ul style={{  listStyleType: 'none',textAlign:'left'}}>
    <li><i style={{color:'red'}} class="fa-solid fa-xmark mx-2"></i> Anything not mentioned under ‘Package Inclusions’</li>
    <li><i style={{color:'red'}} class="fa-solid fa-xmark mx-2"></i> All personal expenses, optional tours and extra meals.</li>
    <li><i style={{color:'red'}} class="fa-solid fa-xmark mx-2"></i> Medical and travel insurance.</li>
    <li><i style={{color:'red'}} class="fa-solid fa-xmark mx-2"></i> Any kind of entry fees anywhere if not mentioned in including.</li>
    <li><i style={{color:'red'}} class="fa-solid fa-xmark mx-2"></i> Tips, travel insurance, medical insurance, laundry charges, liquors, mineral water, telephone charges.</li>
    <li><i style={{color:'red'}} class="fa-solid fa-xmark mx-2"></i> All items of personal nature.</li>
    <li><i style={{color:'red'}} class="fa-solid fa-xmark mx-2"></i> Porter, pony, horse, cable car, boat, train tickets, air tickets charges.</li>
</ul>
    </div>
  )
}
