import React from 'react'
import hero from './images/hero2.jpg'
import hero1 from './images/hero3.jpg'
import './DomesticTour.css'
import TourSlides from './TourSlides'
import Header from './Header'
export default function DomesticTour() {
    const Domestic1=
    [
      {
        imgUrl:'https://i.pinimg.com/originals/94/03/cb/9403cbaa1e4707d659c5052b183f7c29.jpg',
        Title:'CharDham Yatra',
        link:'/chardham-packages'
      },
      {
        imgUrl:'https://th.bing.com/th/id/R.b4e6e1288bb613bef1933885a21f9926?rik=Te1oBie%2b6yZ4vw&riu=http%3a%2f%2fwww.callingindiatour.com%2fuploads%2f0000%2f1%2f2021%2f03%2f11%2fhimachaltours-net-destination-kalpa.jpg&ehk=rSaYxUdBx8p42qBV67xL%2f7An2k0s7locMpbbWMVsRmw%3d&risl=&pid=ImgRaw&r=0',
        Title:'Himachal',
        link:'/himachal-packages'
      },
      {
        imgUrl:'https://www.tripsavvy.com/thmb/4wJONYsP05iMiE3avHzMlkuEcGE=/5120x3413/filters:fill(auto,1)/GettyImages-450784769-58f7b8cd3df78ca1597ccf9c.jpg',
        Title:'Uttrakhand',
        link:'/uttrakhand-packages'
      },
    ]
    const Domestic2=
    [
      {
        imgUrl:'https://www.holidify.com/images/bgImages/JAISALMER.jpg',
        Title:'Rajasthan',
        link:'/rajasthan-packages'
      },
      {
        imgUrl:'https://lepassagetoindia.com/img.php?file=header_149813464288_Goa_2000x1000.jpg',
        Title:'Goa',
        link:'/goa-packages'
      },
      {
        imgUrl:'https://wallpapercave.com/wp/wp3500243.jpg',
        Title:'Kerala'
        ,
        link:'/kerala-packages'
      },
    ]
    const Domestic3=
    [
     
      {
        imgUrl:'https://i.ytimg.com/vi/nDWLL4jmOFI/maxresdefault.jpg',
        Title:'Andaman Nicobar'
        ,
        link:'/andaman-packages'
      },
      {
        imgUrl:'https://www.holidaytravelzone.com/bootstrap/images/package/uploads/original/North_East_Tour_Package.jpg',
        Title:'Northeast'
        ,
        link:'/north-packages'
      },
      {
        imgUrl:'https://th.bing.com/th/id/R.aaf161ea2633c98b355b7b4351b590e1?rik=pvK4OcpqCiPJSA&riu=http%3a%2f%2fwww.shreebalajigroupindia.com%2fwp-content%2fuploads%2f2018%2f05%2fjammu_kashmir_banner.jpg&ehk=MBHHoXbKYZqDJPDwMvvMzDsU3acS6QsZcws2wMN0Lho%3d&risl=&pid=ImgRaw&r=0',
        Title:'Kashmir'
        ,
        link:'/kashmir-packages'
      },
    ]
    // const Domestic4=
    // [
      
    //   {
    //     imgUrl:'https://th.bing.com/th/id/R.8915f033e6629c89e559b8a0a3f5bc19?rik=AsyFtEr%2fyOZ%2bIA&riu=http%3a%2f%2fwww.viamytrip.com%2finternational%2fLadakh-photo-tour-2.jpg&ehk=7p5wuvjPl7xQrTB7CdHlQCFR%2bDwug5Thd%2fJNtJrUIJc%3d&risl=&pid=ImgRaw&r=0',
    //     Title:'Leh Ladak'
    //     ,
    //     link:'/leh-packages'
    //   },
      
    // ]
  return (
    <>
     <section class="pt-5 pb-5" id='back1'>
    <div class="container">
        <div class="row">
            <div class="col-13">
                <div class="container">
    <h1 class="neonText">
         Domestic Tours
    </h1>

 </div>
            </div>
            <div class="col-12" id='check'>
                <div id="carouselExampleIndicators21" class="carousel slide" data-ride="carousel" data-interval="3000">

                    <div class="carousel-inner">
                        <TourSlides Tour={Domestic1} i={1}/>
                        <TourSlides Tour={Domestic2} i={2}/>
                        <TourSlides Tour={Domestic3} i={3}/>
                        {/* <TourSlides Tour={Domestic4} i={3}/> */}
                    </div>
                    
                </div>
            </div>
            <div class="col-13 text-right">
                <a class="btn btn-primary mb-3 mr-1" href="#carouselExampleIndicators21" role="button" data-slide="prev">
                    <i class="fa fa-arrow-left"></i>
                </a>
                <a class="btn btn-primary mb-3 mx-2 " href="#carouselExampleIndicators21" role="button" data-slide="next">
                    <i class="fa fa-arrow-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
    </>
   
  )
}
