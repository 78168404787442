import React, { useEffect } from 'react'
import Header from '../Header'
import PackageCard from '../PackageCard'
export default function Malaysia() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
    const data = [
        {
          title: "Malaysia",
          img: "https://www.tripdezire.com/wp-content/uploads/2017/07/malaysia-slider-6.jpg",
          interval: "5 days / 4 Nights",
          price: "₹35,000",
          description: {
            about:
               "Empty",
            departure: " Malaysia Airport",
            departureTime: "6:00 pm",
          },
          timeline:  [
            {
              day: "Day 01",
              place: "Arrive in Kuala Lumpur",
              about: "Arrive at Kuala Lumpur International Airport. Transfer to Genting Highlands. Arrive and check into a hotel. Relax. Overnight stay at the hotel.",
            },
            {
              day: "Day 02",
              place: "Outdoor Activities",
              about: "Breakfast. Visit Outdoor Theme Park and enjoy the rides. Enjoy a happening Casino night. Return to the hotel for an overnight stay.",
            },
            {
              day: "Day 03",
              place: "Kuala Lumpur visit",
              about: "After breakfast proceed to Kuala Lumpur. On the way, visit Batu Caves. Enjoy shopping around and explore Kuala Lumpur. Overnight stay at the hotel.",
            },
            {
              day: "Day 04",
              place: "Sightseeing in Kuala Lumpur",
              about: "Breakfast. Enjoy a half-day sightseeing tour of Kuala Lumpur and visit Masjid Jamek, Tugu Negara (War Memorial), Chinatown, Petaling Street, Railway station, Thien Hou Temple, Federal Court, Cricket Club, King's Palace, and handicraft center. Get back to the hotel for an overnight stay.",
            },
            {
              day: "Day 05",
              place: "Departure",
              about: "Breakfast. Morning at leisure. Check-out for the airport. Departure.",
            },
          ],
        },
    ]
  return (
    <div>
        <Header/>
         <h1>Malaysia</h1>
         <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </div>
  )
}
