
import React from 'react'
import hero from './images/hero2.jpg'
import hero1 from './images/hero3.jpg'
import './InternationalTour.css'
import TourSlides from './TourSlides'
import Header from './Header'
export default function InternationalTour() {
    const International1=
    [
      {
        imgUrl:'https://i.pinimg.com/originals/bf/7b/48/bf7b48f812ad2ed9dceb860f49d7c803.jpg',
        Title:'Dubai'
        ,
        link:'/dubai-packages'
      },
      {
        imgUrl:'https://mediaim.expedia.com/localexpert/1378878/a052e968-fd05-40be-87f8-3b9c966c6da2.jpg',
        Title:'Singapore'
        ,
        link:'/singapore-packages'
      },
      {
        imgUrl:'https://www.tripsavvy.com/thmb/Xqg77lMGJaUcMQOtFjfTQr5jfY0=/6102x3947/filters:fill(auto,1)/malaysia-travel-583d01ac5f9b58d5b13cec0d.jpg',
        Title:'Malaysia'
        ,
        link:'/malaysia-packages'
      },
    ]
    const International2=
    [
      {
        imgUrl:'https://th.bing.com/th/id/R.4108b5414cc41b997807d6b5ef1766bd?rik=koG13mP0DNZ4HQ&riu=http%3a%2f%2fwww.musafirgoindia.com%2fimages%2fthailand-tour-package-1.jpg&ehk=cES%2b1SCJGMum4gGclOtSMPGFg4CZihtZswCwB1PKV8c%3d&risl=&pid=ImgRaw&r=0',
        Title:'Thailand'
        ,
        link:'/thailand-packages'
      },
      {
        imgUrl:'https://travel-information.org/wp-content/uploads/2019/05/temple.jpg',
        Title:'Bali'
        ,
        link:'/bali-packages'
      },
      {
        imgUrl:'https://www.laviezine.com/wp-content/uploads/2020/09/1599380646_maxresdefault.jpg',
        Title:'Vietnam'
        ,
        link:'/vietnam-packages'
      },
    ]
    const International3=
    [
      {
        imgUrl:'https://viamytrip.com/international/Kurumba_Maldives_by_Ales_Jungmann_14.jpg',
        Title:'Maldives'
        ,
        link:'/maldives-packages'
      },
    ]
  return (
    <div className='international'>
     <section class="pt-5 pb-5" id='back'>
    <div class="container">
        <div class="row">
            <div class="col-13">
                <div class="container">
    <h1 class="n">
     International Tours
    </h1>
 </div>
            </div>
            <div class="col-12" id='check'>
                <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel" data-interval="3000">

                    <div class="carousel-inner">
                        <TourSlides Tour={International1} i={1}/>
                        <TourSlides Tour={International2} i={2}/>
                        <TourSlides Tour={International3} i={3}/>
                    </div>
                    
                </div>
            </div>
            <div class="col-13 text-right">
                <a class="btn btn-primary mb-3 mr-1" href="#carouselExampleIndicators2" role="button" data-slide="prev">
                    <i class="fa fa-arrow-left"></i>
                </a>
                <a class="btn btn-primary mb-3 mx-2 " href="#carouselExampleIndicators2" role="button" data-slide="next">
                    <i class="fa fa-arrow-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
    </div>
   
  )
}
