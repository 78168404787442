import React, { useEffect } from 'react'
import PackageCard from '../PackageCard'
import Header from '../Header'


export default function RajasthanPackage() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
  const data = [
    {
      title: "Jaipur, Ajmer, Pushkar",
      img: "https://wallpaperaccess.com/full/1266903.jpg",
      interval: "4 days / 3 Nights",
      price: "₹10,500",
      readMore: "/chardham-package",
      timeline: [
        {
          place: "Jaipur – Arrival",
          activities: "Pick up from Railway Station / Airport, Check in at Hotel with Welcome Drinks, Sightseeing: Hawa Mahal, Jantar Mantar, City Palace, Albert Hall, Birla Mandir, Relax & Overnight Stay at Jaipur Hotel",
        },
        {
          place: "Jaipur",
          activities: "Breakfast at Hotel, Sightseeing: Hawa Mahal, Jantar Mantar, City Palace, Albert Hall, Birla Mandir, Visit Chowki Dhani for Dinner an ethnic Village (Dinner at own Cost), Relax & Overnight Stay at Jaipur Hotel",
        },
        {
          place: "Ajmer & Pushkar",
          activities: "Breakfast at Hotel & Check Out, Drive to Ajmer: Sightseeing - Dargah Sharif, Soniji Ki Nasiyaan, Dhai Din ka Jhopra, Anasagar Lake, Drive to Pushkar: Brahma Temple, Pushkar Lake, Savitri Mata Mandir Ropeway, Rangji Temple, Pushkar Fair Ground, Check in at Hotel with Welcome Drinks, Relax & Overnight Stay at Ajmer / Pushkar Hotel",
        },
        {
          place: "Jaipur – Departure",
          activities: "Breakfast at the Hotel & Check out, Drive to Jaipur, Drop at Airport / Railway Station, Tour Ends with Sweet Memories",
        },
      ],
      description: {
        about:
           "Empty",
        departure: "Airport/ Railway Station",
        departureTime: "9:30",
      },

    },
    {
      title: "Udaipur, Mount Abu",
      img: "https://pickyourtrail.com/blog/wp-content/uploads/2020/07/shubham-shrivastava-9FHxxFwxMrI-unsplash-scaled.jpg",
      interval: "4 days / 3 Nights",
      price: "₹11,500",
      readMore: "/chardham-package",
      timeline: [
        {
          place: "Udaipur – Arrival",
          about: "Pick up from Railway Station / Airport, Check in at Hotel with Welcome Drinks, Sightseeing: Fateh Sagar Lake, Under the Sun, Monsoon Palace Evening Shopping at own Cost – Bada Bazar, Hathi Pol Bazar, Relax & Overnight Stay at Udaipur Hotel",
        },
        {
          place: "Udaipur",
          about: "Breakfast at Hotel, Sightseeing: City Palace, Sahelion ki Badi, Jagdish Temple, Jag Mandir Palace, Zoo, Sukhadia Circle, Jagmandir, Lake Pichola, Boating (Own Cost), Relax & Overnight Stay at Udaipur Hotel",
        },
        {
          place: "Mount Abu",
          about: "Breakfast at Hotel & Check Out, Drive to Mount Abu, Check in at Hotel with Welcome Drinks, Sightseeing: Lover's Point, Guru Shikhar, Dilwara Jain Temple, Sunset Point / Honeymoon Point, Relax & Overnight Stay at Mount Abu Hotel",
        },
        {
          place: "Udaipur – Departure",
          about: "Breakfast & Check out, Drive to Udaipur, Drop at Railway Station / Airport, Tour Ends with Sweet Memories",
        },
      ],
      description: {
        about:
           "Empty",
        departure: "Airport/ Railway Station",
        departureTime: "9:30",
      },

    },
    {
      title: "Jaisalmer, Sam Sand Dunes, Jodhpur",
      img: "https://i.pinimg.com/originals/04/23/c3/0423c3023efe94399b73c2246f115220.jpg",
      interval: "4 days / 3 Nights",
      price: "₹9,800",
      readMore: "/chardham-package",
      timeline: [
        {
          place: "Jaisalmer",
          about: "Pick up from Railway Station / Airport, Check in at Hotel with Welcome Drinks, Sightseeing: Jaisalmer Fort, Gadisar Lake, Salim Singh ki Haveli, Patwon ki Haveli, Relax & Overnight Stay at Jaisalmer Hotel",
        },
        {
          place: "Sam Sand Dunes",
          about: "Breakfast at the Hotel & Check out, Jaisalmer Sightseeing: Bada Bagh, Vyas Chhatri & War Museum, Enroute Visit – Kuldhara Haunted Village, Jain Temple & Wind Park, Reach Camp & Check in & follow the below itinerary, Traditional welcome with Aarti, Teeka, Dhol, Stay in Luxury Swiss Tent, 2 Bottles of 1 litre Mineral Water, Uses of Swimming Pool (In costume), Camel Safari (2 persons on each camel), Camp Fire, Rajasthani Folk Music, Rajasthani Folk Dance, Musical Night, Hi-Tea, Buffet Dinner, Relax & Overnight stay at Sam Sand Dunes Camp",
        },
        {
          place: "Jodhpur",
          about: "Breakfast at the Camp & Check out, Drive to Jodhpur, Check in at Hotel with Welcome Drinks, Sightseeing: Mehrangarh Fort/Jodhpur Fort, Sheesh Mahal, Umaid Bhawan Palace & Museum, Jaswant Thada, Mandori Garden, Relax & Overnight Stay at Jodhpur Hotel",
        },
        {
          place: "Jodhpur – Departure",
          about: "Breakfast at the Hotel & Check out, Drop at Airport / Railway Station, Tour Ends with Sweet Memories",
        },
      ],
      description: {
        about:
           "Empty",
        departure: "Airport/ Railway Station",
        departureTime: "9:30",
      },

    },
    {
      title: "Jaisalmer, Sam Sand Dunes",
      img: "https://i.redd.it/tm5n5fldtw1z.jpg",
      interval: "3 days / 2 Nights",
      price: "₹6,500",
      readMore: "/chardham-package",
      timeline: [
        {
          place: "Jaisalmer",
          about: "Pick up from Railway Station / Airport, Check in at Hotel with Welcome Drinks, Sightseeing: Jaisalmer Fort, Gadisar Lake, Salim Singh ki Haveli, Patwon ki Haveli, Relax & Overnight Stay at Jaisalmer Hotel",
        },
        {
          place: "Sam Sand Dunes",
          about: "Breakfast at the Hotel & Check out, Jaisalmer Sightseeing: Bada Bagh, Vyas Chhatri & War Museum, Enroute Visit – Kuldhara Haunted Village, Jain Temple & Wind Park, Reach Camp & Check in & follow the below itinerary, Traditional welcome with Aarti, Teeka, Dhol, Stay in Luxury Swiss Tent, 2 Bottles of 1 litre Mineral Water, Uses of Swimming Pool (In costume), Camel Safari (2 persons on each camel), Camp Fire, Rajasthani Folk Music, Rajasthani Folk Dance, Musical Night, Hi-Tea, Buffet Dinner, Relax & Overnight stay at Sam Sand Dunes Camp",
        },
        {
          place: "Sand Dunes– Departure",
          about: "Breakfast at the Hotel & Check out, Drop at Airport / Railway Station, Tour Ends with Sweet Memories",
        },
      ],
      description: {
        about:
           "Empty",
        departure: "Airport/ Railway Station",
        departureTime: "9:30",
      },

    },
    {
      title: "Udaipur, Jodhpur",
      img: "https://www.wtravelmagazine.com/wp-content/uploads/2018/12/23.28.1-The-Blue-City-and-Mehrangarh-Fort-by-Sean-Hsu.jpg",
      interval: "4 days / 3 Nights",
      price: "₹10,500",
      readMore: "/chardham-package",
      timeline:  [
        {
          place: "Udaipur – Arrival",
          about: "Pick up from Railway Station / Airport, Check in at Hotel with Welcome Drinks, Sightseeing: Fateh Sagar Lake, Under the Sun, Monsoon Palace Evening Shopping at own Cost – Bada Bazar, Hathi Pol Bazar, Relax & Overnight Stay at Udaipur Hotel",
        },
        {
          place: "Udaipur",
          about: "Breakfast at Hotel, Sightseeing: City Palace, Sahelion ki Badi, Jagdish Temple, Jag Mandir Palace, Zoo, Sukhadia Circle, Jagmandir, Lake Pichola, Boating (Own Cost), Relax & Overnight Stay at Udaipur Hotel",
        },
        {
          place: "Jodhpur",
          about: "Breakfast at Hotel & Check out, Drive to Jodhpur, Check in at Hotel with Welcome Drinks, Sightseeing: Mehrangarh Fort/Jodhpur Fort, Sheesh Mahal, Umaid Bhawan Palace & Museum, Jaswant Thada, Mandori Garden, Relax & Overnight Stay at Jodhpur Hotel",
        },
        {
          place: "Jodhpur / Udaipur – Departure",
          about: "Breakfast at the Hotel & Check out, Drive to Jodhpur / Udaipur, Drop at Airport / Railway Station, Tour Ends with Sweet Memories",
        },
      ],
      description: {
        about:
           "Empty",
        departure: "Airport/ Railway Station",
        departureTime: "9:30",
      },

    },
   
  ];
  return (
    <>
      <Header />
      <h1>Rajsthan</h1>

      <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </>
  );
}
