import React from 'react';
import './PackageCard.css'; 
import { useNavigate } from 'react-router-dom';

export default function PackageCard(props) {
  const navigate = useNavigate();
  const { data } = props;

  return (
    <div className="card1">
      <img className="card-image1" src={data.img} alt={""} />
      <div className="overlay">
        <button className="read-more-btn" onClick={() => navigate('/package-info',{state:data} )}>
          Read More
        </button>
      </div>
      <div className="card-content">
        <h2 className="card-title">{data.title}</h2>
        <div className='container1 d-flex my-2'>
          <p className="card-description left">{data.interval}</p>
          <h1 style={{ color: 'darkblue' }} className="right">{data.price} / <i class="fa-solid fa-person"></i></h1>
        </div>
      </div>
    </div>
  );
}