import React from 'react'
import Accordion from './Accordion'

export default function Timeline({data}) {


  return (<div className='container'  style={{
     
    maxWidth: '700px', 
    backgroundColor: 'white',
    border: '1px solid #ccc',
   
  }}>
 
      <h1 style={{color:'#551b7bc9'}}>{data.title}</h1>
    <div class="timelinecontainer" >
    <div   style={{width:'100%'}}>
    <div class="accordion " id="accordionFlushExample">

 {data.timeline?
   data.timeline.map((item,i)=>{
    return (
      <Accordion item={item} value={i}/>
    )
   }):""
 }
 {data.info?
   data.info.map((item,i)=>{
    return (
      <>
     {item.about? <h3 style={{textAlign:'justify'}}>{item.about} </h3>
      :<p style={{textAlign:'justify'}}>{item.data} </p>
    }
      </>
    )
   }):""
 }
  
</div>
    </div>
    </div>
    </div>
  )
}
