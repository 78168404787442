import React, { useState } from 'react'
import  emailjs from '@emailjs/browser'
export default function ContactForm(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [message, setMessage] = useState("");
    const handleclick = () => 
    {
  
      const  service_id= 'service_gddh5k4'
      const  template_id= 'template_926yjtm'
      const  key= 'OilYTcoRSq72_Mcor'
    var templateParams = {
      from_name: name,
      to_name: "Admire Tourism",
      from_email: email,
      contact:contact,
      category:props.category,
      message:message
  };
  if(email&&name&&contact&&message){
    emailjs.send(service_id, template_id, templateParams,key)
    .then(function(response) {
      //  console.log('SUCCESS!', response.status, response.text);
      alert("Your form Submitted SuccessFully, Admire Tourism will contact you soon")
       setName("")
       setEmail("")
       setContact("")
       setMessage("")
    }, function(error) {
      alert("Failed to book, Try again or contact directly")
    });
  }
  else{
    alert("Please Fill all the Fields")
  }
  
    };

  return (
    <>
       <form>
                    <h2 className="text-2xl">{props.title}</h2>
                    <div>
                      <label className="text-sm">Name</label>
                      <br></br>
                      <input
                        className="bg-gray-50 border border-gray-300 
										text-sm rounded-lg focus:border-blue-500 
										w-full p-2.5"
                        type="name"
                        placeholder="enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <br></br>
                      <label className="text-sm">Email Address*</label>
                      <br></br>
                      <input
                        className="bg-gray-50 border border-gray-300 
										text-sm rounded-lg focus:border-blue-500 
										w-full p-2.5"
                        type="email"
                        placeholder="abc@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <br></br>
                      <label className="text-sm">Contact No.</label>
                      <br></br>
                      <input
                        className="bg-gray-50 border border-gray-300 
										text-sm rounded-lg focus:border-blue-500 
										w-full p-2.5"
                        type="tel"
                        placeholder="enter your contact no."
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                      />
                      <br></br>
                      <label className="text-sm">Message</label>
                      <br></br>
                      <textarea
                        className="bg-gray-50 border border-gray-300 
											text-sm rounded-lg 
											focus:border-blue-500 
											w-full p-2.5"
                        rows="4"
                        cols="25"
                        maxlength="300"
                        placeholder="Max Allowed Characters: 300"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      <br></br>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleclick}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
    </>
  )
}
