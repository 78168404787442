import React from 'react'
import Header from './Header';
import doc from './WorkvisaDoc/doc.pdf'
import Card from './Card';
import img1 from './WorkVisaimg/img1.jpeg'
import img2 from './WorkVisaimg/img2.jpeg'
import img3 from './WorkVisaimg/img3.jpeg'
import img4 from './WorkVisaimg/img4.jpeg'
import img5 from './WorkVisaimg/img5.jpeg'
import img6 from './WorkVisaimg/img6.jpeg'
import img7 from './WorkVisaimg/img7.jpeg'
import img8 from './WorkVisaimg/img8.jpeg'
import img9 from './WorkVisaimg/img9.jpeg'
import img10 from './WorkVisaimg/img10.jpeg'
import img11 from './WorkVisaimg/img11.jpeg'
import img12 from './WorkVisaimg/img12.jpeg'
import img13 from './WorkVisaimg/img13.jpeg'
import img14 from './WorkVisaimg/img14.jpeg'
import img15 from './WorkVisaimg/img15.jpeg'
export default function WorkVisa() {
    const workVisa = [
        { title: "Azerbaijan", url: img3 ,doc },
        { title: "Azerbaijan", url: img4 ,doc },
        { title: "Azerbaijan", url: img5 ,doc },
        { title: "Azerbaijan", url: img6 ,doc },
        { title: "Azerbaijan", url: img8 , doc},
        { title: "Azerbaijan", url: img11 ,doc },
        { title: "Azerbaijan", url: img14 ,doc },
        { title: "Azerbaijan", url: img7 ,doc },
        { title: "Azerbaijan", url: img15 ,doc },
        { title: "Armenia", url: img1,doc },
        { title: "Azerbaijan", url: img2 ,doc },
        { title: "Azerbaijan", url: img9 , doc},
        { title: "Azerbaijan", url: img10 ,doc },
        { title: "Azerbaijan", url: img12 ,doc },
        { title: "Azerbaijan", url: img13 , doc},
      
    ]
  return (
    <>
      <Header />
      <h1>Work Visa</h1>
      <div className="container my-3">
        <div className="row" style={{ marginTop: "50px" }}>
          <div className="container">
            <div className="row">
              {workVisa.map((element, i) => {
                return (
                  <div className="col-md-4" key={i}>
                    <Card img={element.url} title={""} doc={doc} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
