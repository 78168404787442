import PackageCard from "../PackageCard";
import Header from "../Header";
import "./Package.css"; // Import your CSS file
import React, { useEffect } from 'react'
export default function ChardhamPackages() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
  const data = [
    {
      title: "Chardham",
      img: "https://i.pinimg.com/originals/79/37/cf/7937cf16c051ec38c50db87a8caa5d91.jpg",
      interval: "10 days / 9 Nights",
      price: "₹19500",
      readMore: "/chardham-package",
      timeline: [
        {
          id: 1,
          place: "Haridwar – Barkot (200kms/7 hrs)",
          about:
            "Welcome to Char Dham Yatra tour! you will be met by a Admire Tourism representative On arrival. Board the Cab and drive to Barkot, via Dehradun & Mussoorie (if Time or Traffic Allow) . En-route visit Kempty Fall and enjoy the beauty of Himalayas, later drive straight to Barkot for night stay.  ",
        },
        {
          id: 2,
          place: "Barkot - Yamunotri – Barkot (45 Kms road/ 6kms Trek)",
          about:
            "Drive to Jankichatti, trek starts from here to Yamunotri (6kms). On arrival leave the vehicle and start to ascend on trek route either by walk or pony/doli (at own cost). On arrival at Yamunotri purchase Prasad form local small shops, then proceed to holy dip in natural hot water spring or in river Yamuna (icy cold water). One can cook rice in “Surya Kund” (hotwater spring), dipping in a muslin cloth and take cooked rice home as “Prasadm”. Pooja can be offered to mythological “Divya Shila”. Take blessings of Mata Yamuna and trek down to Jankichatti. Later drive back to Barkot for night stay. ",
        },
        {
          id: 3,
          place: "Barkot – Uttarkashi - (90kms/4hrs)",
          about:
            "Morning depart to Uttarkashi, on arrival check in at hotel, later visit famous Kashi Vishwanath temple at Uttarkashi. Overnight stay at hotel",
        },
        {
          id: 4,
          place: "Uttarkashi Gangotri -Uttarkashi (105 kms/4 hrs)",
          about:
            "Morning proceed to Gangotri (3048 mts), en-route enjoy the drive parallel to Bhagirathi River and the most magnificent view of the Himalayas. On arrival Gangotri have a holy dip in river Bhagirathi (icy cold water). Perform Pooja and Hindu rites at the bank of river. Then proceed to darshan, of Mata Ganga, Lord Shiva, Hanuman, Vinayka and BhagirathMaharaj, have blessings and return back to Uttarkashi. Overnight stay at Uttarkashi.",
        },
        {
          id: 5,
          place: "Uttarkashi -– Guptkashi (220 kms/9 Hrs)",
          about:
            "Morning drive to Guptkashi or Phata. later drive to Guptkashi & visit Shri Kashi Viswanath Temple (Ardhanariswar Temple) Later do Registration for visiting Kedarnath, overnight stay at Hotel. Rudraprayag: It is the confluence of river Mandakini and Alaknanda, Rudranath, Temple & Narad Silla ",
        },
        {
          id: 6,
          place: "Day 06: Guptkashi - Kedarnath (18 kms Trek)",
          about:
            "Early morning drive to Sonprayag / Phata (for trek route or helipad)trek starts from here to Kedarnath (3584 mts) on foot or Heli/doli/pony (at own cost). Tour members should carry personal medicines, heavy woolen, toiletries and clothes. After performing Pooja & Darshan of Shri Kedarnath ji. Stay overnight at Kedarnath on your own expense",
        },
        {
          id: 7,
          place: "Kedarnath - Guptkashi (18 kms Trek Down)",
          about:
            "Today morning trek down to Guptkashi and Overnight Stay at Guptkashi.",
        },
        {
          id: 8,
          place: "Guptkashi - Joshimath (190 kms / 8 Hrs)",
          about:
            "Morning After breakfast transfer to Badrinath, en-route visit Karanprayag, Nandprayag, Vishnu Prayag, & Joshimath (Narshimha Temple) on arrival check in at hotel,overnight stay at hotel.",
        },
        {
          id: 9,
          place: "Joshimath - Badrinath - Joshimath / Pipalkoti (80kms/4 hrs)",
          about:
            "Morning visit temple after a holy dip in Taptkund. Proceed through colorful entrance of the temple have Darshan of Badrivishal along with Ganesh, Kubera, Garuda, Narada, Udhhav, Nar & Narayan. Also see another small temples inside the temple compound i.e. Laxmimata, Nar-Narayana,   Hanuman,   Ghantakarn   and   Kaamdhenu.   One   can   get „Charnamirt‟(abhishekamjal) in side compound. Brahamakapal is significant for Pinddam Shraddh of ancestors (Pitrus), one can perform rite here.  Later a visit of Mana Village (India‟s last village towards Indo-Tibetan border), which includes Vyasa Gufa, Ganesh Gufa, Bhimpul, Saraswati river etc, In the evening transfer to Joshimath/ Pipalkoti, Overnight stay.",
        },
        {
          id: 10,
          place: "Pipalkoti/Joshimath – Haridwar (220kms/8 hrs)",
          about:
            "Morning after transfer to Haridwar, en-route visit Devprayag the confluence of Alaknanda and Bhagirathi. Here onwards downstream river is called Ganges, later transfer to Rishikesh, - Shivananda Ashram, Ram Jhoola, Swarg Ashram, Geeta Ashram, Laxman Jhoola, then Process to Haridwar on arrival proceed to perform ritualistic bathing on the banks of pious river Ganges and join the enchanting evening Aarti at Har-ki-Pauri. Brahma Kund, the spot where the Amrit (nectar) spilled out of the pitcher, is located at Harki Pauri and is considered to be prime ghat of Haridwar. Here tour ends here with happy memories to be cherished forever.",
        },
      ],
      description: {
        about:
          "Uttarakhand, also renowned as Devbhoomi or the Land of Gods, is dedicated home to a large number of temples and attracts pilgrims throughout the year. The greatest Char Dham Yatra tour packages provide a perfect opportunity to gratify your religious curiosity, observe the rich Hindu culture, and discover the realm's captivating natural view. The Char Dham Yatra plans encompass trips to Uttarakhand's holiest shrines. Our Yatra, or pilgrimage, introduces you to visiting four sacred destinations in the Himalayan Mountain range: notably Gangotri Dham, Yamunotri Dham, Kedarnath Dham, as well as Badrinath Dham. The very first place on the Char Dham route map is the Badrinath Dham, which itself is located along the riverbank of the Alaknanda River. Additional renowned attractions around Badrinath Dham to explore with Char Dham Yatra packages are Rudraprayag, Hemkund Sahib, Guptkashi, and Surya Kund. Puri Dham, approximately 60 kilometres from Bhubaneshwar, is the second visit on the Char Dham Yatra. Near Puri Dham, you may indeed view Narendra Pokhari, Gudicha Temple, and Puri Beach. The devotion proceeds with Rameshwaram Dham. An excellent Char Dham tour would let tourists to really see Dhanushkodi Temple, Agnitheertham, Pamban Bridge, and Jatayu Tirtham. The heavenly pilgrimage tour concludes in Dwarka Dham, which itself is recognized for the medieval Dwarkadhish Temple. High points of good Char Dham Yatra trip packages comprise travels to Beyt Island, Dwarka Beach, Gomti Ghat, and Gopi Talav.",
        departure: "Haridwar",
        departureTime: "5:00 pm",
      },
    },
    {
      title: "Chardham",
      img: "https://images.assettype.com/swarajya%2F2019-11%2F41978537-cd43-4212-8a67-cb858b9e1fec%2Fdham.jpg?w=1200&auto=format%2Ccompress&ogImage=true",
      interval: "12 days / 11 Nights",
      price: "₹21500",
      readMore: "/chardham-package",
      timeline: [
        {
          id: 1,
          place: "Haridwar (230kms/7 Hrs)",
          about:
            "Welcome to Char Dham Yatra tour! you will be met by a Admire Tourism representative On arrival. Board the Cab and drive to Haridwar. Overnight stay in Haridwar. ",
        },
        {
          id: 2,
          place: "Haridwar – Barkot (200kms/7 hrs)",
          about:
            "Morning after breakfast proceed to Barkot, via Dehradun & Mussoorie (if Time or Traffic Allow) . En-route visit Kempty Fall and enjoy the beauty of Himalayas, later drive straight to Barkot for night stay.",
        },
        {
          id: 3,
          place: "Barkot - Yamunotri – Barkot (45 Kms road/ 6kms Trek)",
          about:
            "Drive to Jankichatti, trek starts from here to Yamunotri (6kms). On arrival leave the vehicle and start to ascend on trek route either by walk or pony/doli (at own cost). On arrival at Yamunotri purchase Prasad form local small shops, then proceed to holy dip in natural hot water spring or in river Yamuna (icy cold water). One can cook rice in “Surya Kund” (hotwater spring), dipping in a muslin cloth and take cooked rice home as “Prasadm”. Pooja can be offered to mythological “Divya Shila”. Take blessings of Mata Yamuna and trek down to Jankichatti. Later drive back to Barkot for night stay. ",
        },
        {
          id: 4,
          place: "Barkot – Uttarkashi - (90kms/4hrs)",
          about:
            "Morning depart to Uttarkashi, on arrival check in at hotel, later visit famous Kashi Vishwanath temple at Uttarkashi. Overnight stay at hotel",
        },
        {
          id: 5,
          place: "Uttarkashi Gangotri -Uttarkashi (105 kms/4 hrs)",
          about:
            "Morning proceed to Gangotri (3048 mts), en-route enjoy the drive parallel to Bhagirathi River and the most magnificent view of the Himalayas. On arrival Gangotri have a holy dip in river Bhagirathi (icy cold water). Perform Pooja and Hindu rites at the bank of river. Then proceed to darshan, of Mata Ganga, Lord Shiva, Hanuman, Vinayka and BhagirathMaharaj, have blessings and return back to Uttarkashi. Overnight stay at Uttarkashi.",
        },
        {
          id: 6,
          place: "Uttarkashi -– Guptkashi (220 kms/9 Hrs)",
          about:
            "Morning drive to Guptkashi or Phata. later drive to Guptkashi & visit Shri Kashi Viswanath Temple (Ardhanariswar Temple) Later do Registration for visiting Kedarnath, overnight stay at Hotel. Rudraprayag: It is the confluence of river Mandakini and Alaknanda, Rudranath, Temple & Narad Silla ",
        },
        {
          id: 7,
          place: "Day 06: Guptkashi - Kedarnath (18 kms Trek)",
          about:
            "Early morning drive to Sonprayag / Phata (for trek route or helipad)trek starts from here to Kedarnath (3584 mts) on foot or Heli/doli/pony (at own cost). Tour members should carry personal medicines, heavy woolen, toiletries and clothes. After performing Pooja & Darshan of Shri Kedarnath ji. Stay overnight at Kedarnath on your own expense",
        },
        {
          id: 8,
          place: "Kedarnath - Guptkashi (18 kms Trek Down)",
          about:
            "Today morning trek down to Guptkashi and Overnight Stay at Guptkashi.",
        },
        {
          id: 9,
          place: "Guptkashi - Joshimath (190 kms / 8 Hrs)",
          about:
            "Morning After breakfast transfer to Badrinath, en-route visit Karanprayag, Nandprayag, Vishnu Prayag, & Joshimath (Narshimha Temple) on arrival check in at hotel,overnight stay at hotel.",
        },
        {
          id: 10,
          place: "Joshimath - Badrinath - Joshimath / Pipalkoti (80kms/4 hrs)",
          about:
            "Morning visit temple after a holy dip in Taptkund. Proceed through colorful entrance of the temple have Darshan of Badrivishal along with Ganesh, Kubera, Garuda, Narada, Udhhav, Nar & Narayan. Also see another small temples inside the temple compound i.e. Laxmimata, Nar-Narayana,   Hanuman,   Ghantakarn   and   Kaamdhenu.   One   can   get „Charnamirt‟(abhishekamjal) in side compound. Brahamakapal is significant for Pinddam Shraddh of ancestors (Pitrus), one can perform rite here.  Later a visit of Mana Village (India‟s last village towards Indo-Tibetan border), which includes Vyasa Gufa, Ganesh Gufa, Bhimpul, Saraswati river etc, In the evening transfer to Joshimath/ Pipalkoti, Overnight stay.",
        },
        {
          id: 11,
          place: "Pipalkoti/Joshimath – Haridwar (220kms/8 hrs)",
          about:
            "Morning after transfer to Haridwar, en-route visit Devprayag the confluence of Alaknanda and Bhagirathi. Here onwards downstream river is called Ganges, later transfer to Rishikesh, - Shivananda Ashram, Ram Jhoola, Swarg Ashram, Geeta Ashram, Laxman Jhoola, then Process to Haridwar on arrival proceed to perform ritualistic bathing on the banks of pious river Ganges and join the enchanting evening Aarti at Har-ki-Pauri. Brahma Kund, the spot where the Amrit (nectar) spilled out of the pitcher, is located at Harki Pauri and is considered to be prime ghat of Haridwar. Here tour ends here with happy memories to be cherished forever.",
        },
        {
          id: 12,
          place: "Haridwar - Delhi",
          about:
            "Morning Check-out and depart for Delhi. Here tour ends here with happy memories to be cherished forever.",
        },
      ],
      description: {
        about:
        "Uttarakhand, also renowned as Devbhoomi or the Land of Gods, is dedicated home to a large number of temples and attracts pilgrims throughout the year. The greatest Char Dham Yatra tour packages provide a perfect opportunity to gratify your religious curiosity, observe the rich Hindu culture, and discover the realm's captivating natural view. The Char Dham Yatra plans encompass trips to Uttarakhand's holiest shrines. Our Yatra, or pilgrimage, introduces you to visiting four sacred destinations in the Himalayan Mountain range: notably Gangotri Dham, Yamunotri Dham, Kedarnath Dham, as well as Badrinath Dham. The very first place on the Char Dham route map is the Badrinath Dham, which itself is located along the riverbank of the Alaknanda River. Additional renowned attractions around Badrinath Dham to explore with Char Dham Yatra packages are Rudraprayag, Hemkund Sahib, Guptkashi, and Surya Kund. Puri Dham, approximately 60 kilometres from Bhubaneshwar, is the second visit on the Char Dham Yatra. Near Puri Dham, you may indeed view Narendra Pokhari, Gudicha Temple, and Puri Beach. The devotion proceeds with Rameshwaram Dham. An excellent Char Dham tour would let tourists to really see Dhanushkodi Temple, Agnitheertham, Pamban Bridge, and Jatayu Tirtham. The heavenly pilgrimage tour concludes in Dwarka Dham, which itself is recognized for the medieval Dwarkadhish Temple. High points of good Char Dham Yatra trip packages comprise travels to Beyt Island, Dwarka Beach, Gomti Ghat, and Gopi Talav.",
        departure: "Delhi",
        departureTime: "9:00 A.M",
      },
    },
    {
      title: "Dodham",
      img: "https://incredibletripplanners.in/images/room/bkd.jpg",
      interval: "6 days / 5 Nights",
      price: "₹16800",
      readMore: "/chardham-package",
      timeline: [
        {
          id: 1,
          place: "Haridwar – Guptkashi (220kms/8-9hr) Height: 1319 MTS.",
          about:
            "Welcome to Do Dham Yatra tour! You will be met by an Admire Tourism representative. Drive to Guptkashi via Moolgarh & Lambgoan. Enroute, enjoy the beautiful river Mandakini at Tilwara. Visit ArdhNarishwar Temple in Guptakashi. Check into the hotel on arrival in Guptakashi. Overnight stay at Guptakashi.",
        },
        {
          id: 2,
          place: "Guptkashi – Kedarnath (30kms by road & 19kms Trek one way) Height: 3384 MTS.",
          about:
            "Morning after breakfast, departure for Kedarnath Dham. Enjoy Kedarnath Darshan. Overnight stay at Kedarnath. By Helicopter: Transfer to your pre-booked Helipad. Inform the driver about your arrival time. By Trek: Drop at Sonprayag, take a local jeep for Gaurikund, and start the trek to Kedarnath Ji.",
        },
        {
          id: 3,
          place: "Kedarnath - Guptkashi",
          about:
            "Morning after darshan, return to Guptkashi by trek or helicopter. Overnight stay at the hotel.",
        },
        {
          id: 4,
          place: "Guptkashi - Joshimath (190 kms / 8 Hrs)",
          about:
            "Morning after breakfast, transfer to Badrinath. En-route visit Karanprayag, Nandprayag, Vishnu Prayag, & Joshimath (Narshimha Temple). Check-in at the hotel. Overnight stay.",
        },
        {
          id: 5,
          place: "Joshimath - Badrinath - Joshimath / Pipalkoti (80kms/4 hrs)",
          about:
            "Morning visit Badrinath temple, have a holy dip in Taptkund, and explore other small temples. Visit Mana Village in the evening. Transfer to Joshimath/Pipalkoti. Overnight stay.",
        },
        {
          id: 6,
          place: "Pipalkoti/Joshimath – Haridwar (220kms/8 hrs)",
          about:
            "Morning after transfer to Haridwar. En-route visit Devprayag, Rishikesh, Shivananda Ashram, Ram Jhoola, Swarg Ashram, Geeta Ashram, Laxman Jhoola. Perform ritualistic bathing on the banks of the Ganges and join the evening Aarti at Har-ki-Pauri. Drive to Haridwar railway station.",
        },
      ],
      description: {
        about: "Embark on a spiritual sojourn with our exclusive Dodham package, featuring the divine destinations of Kedarnath and Badrinath. Immerse yourself in the sacred aura of Kedarnath, nestled amidst the breathtaking Himalayas, where ancient temples and pristine landscapes await. Journey onward to Badrinath, a haven of tranquility and spirituality, where the revered Badrinath Temple stands as a testament to centuries of devotion. Our meticulously crafted tour ensures a seamless and enriching pilgrimage experience, allowing you to connect with the divine while exploring the cultural treasures of Dodham. Book your pilgrimage now for a soul-stirring adventure in the heart of the Himalayas.",
        departure: "Haridwar",
        departureTime: "5:00 pm",
      },
    },
    {
      title: "Dodham",
      img: "https://th.bing.com/th/id/OIP.Z1wlvLsj_bAh2aD6HIzZXgHaD5?w=1024&h=538&rs=1&pid=ImgDetMain",
      interval: "8 days / 7 Nights",
      price: "₹19800",
      readMore: "/chardham-package",
      timeline: [
        {
          id: 1,
          place: "Delhi – Haridwar (230kms/7 Hrs)",
          about:
            "Welcome to Char Dham Yatra tour! You will be met by an Admire Tourism representative on arrival. Board the cab and drive to Haridwar. Overnight stay in Haridwar.",
        },
        {
          id: 2,
          place: "Haridwar – Guptkashi (220kms/8-9hr) Height: 1319 MTS.",
          about:
            "Morning after breakfast, check out from the hotel and drive to Guptkashi via Moolgarh & Lambgoan. Enroute, enjoy the beautiful river Mandakini at Tilwara. Visit ArdhNarishwar Temple in Guptakashi. Check into the hotel on arrival in Guptakashi. Overnight stay at Guptakashi.",
        },
        {
          id: 3,
          place: "Guptkashi – Kedarnath (30kms by road & 19kms Trek one way) Height: 3384 MTS.",
          about:
            "Morning after breakfast, departure for Kedarnath Dham. Enjoy Kedarnath Darshan. Overnight stay at Kedarnath. By Helicopter: Transfer to your pre-booked Helipad. By Trek: Drop at Sonprayag, take a local jeep for Gaurikund, and start the trek to Kedarnath Ji.",
        },
        {
          id: 4,
          place: "Kedarnath- Guptkashi",
          about:
            "Morning after darshan, return to Guptkashi by trek or helicopter. Overnight stay at the hotel.",
        },
        {
          id: 5,
          place: "Guptkashi - Joshimath (190 kms / 8 Hrs)",
          about:
            "Morning after breakfast, transfer to Badrinath. En-route visit Karanprayag, Nandprayag, Vishnu Prayag, & Joshimath (Narshimha Temple). Check-in at the hotel. Overnight stay.",
        },
        {
          id: 6,
          place: "Joshimath - Badrinath - Joshimath / Pipalkoti (80kms/4 hrs)",
          about:
            "Morning visit Badrinath temple, have a holy dip in Taptkund, and explore other small temples. Visit Mana Village in the evening. Transfer to Joshimath/Pipalkoti. Overnight stay.",
        },
        {
          id: 7,
          place: "Pipalkoti/Joshimath – Haridwar (220kms/8 hrs)",
          about:
            "Morning after transfer to Haridwar. En-route visit Devprayag, Rishikesh, Shivananda Ashram, Ram Jhoola, Swarg Ashram, Geeta Ashram, Laxman Jhoola. Perform ritualistic bathing on the banks of the Ganges and join the enchanting evening Aarti at Har-ki-Pauri. Drive drop to Haridwar Hotel.",
        },
        {
          id: 8,
          place: "Haridwar - Delhi",
          about:
            "Morning check-out and depart for Delhi. The tour ends here with happy memories to be cherished forever.",
        },
      ],
      description: {
        about: "Embark on a spiritual sojourn with our exclusive Dodham package, featuring the divine destinations of Kedarnath and Badrinath. Immerse yourself in the sacred aura of Kedarnath, nestled amidst the breathtaking Himalayas, where ancient temples and pristine landscapes await. Journey onward to Badrinath, a haven of tranquility and spirituality, where the revered Badrinath Temple stands as a testament to centuries of devotion. Our meticulously crafted tour ensures a seamless and enriching pilgrimage experience, allowing you to connect with the divine while exploring the cultural treasures of Dodham. Book your pilgrimage now for a soul-stirring adventure in the heart of the Himalayas.",
        departure: "Delhi",
        departureTime: "9:30",
      },
    },
    {
      title: "Ekdham",
      img: "https://th.bing.com/th/id/R.a8b1bda7444b39ee145911815c78fcd9?rik=4xZV7MIOG9hn6A&riu=http%3a%2f%2fbontravelindia.com%2fwp-content%2fuploads%2f2021%2f10%2fKedarnath-Temple-Uttarakhand.jpg&ehk=dp3z53tRguJAd37gcVV9MmDdeZ%2fkm%2f1tBDgVYl3hkcg%3d&risl=&pid=ImgRaw&r=0",
      interval: "3 days / 2 Nights",
      price: "₹9,000",
      readMore: "/chardham-package",
      timeline: [
        {
          id: 1,
          place: "Haridwar – Guptkashi (220kms/8-9hr) Height: 1319 MTS.",
          about:
            "Welcome to Do Dham Yatra tour! You will be met by an Admire Tourism representative on arrival. Drive to Guptkashi via Moolgarh & Lambgoan. Enroute, enjoy the beautiful river Mandakini at Tilwara. Visit ArdhNarishwar Temple in Guptakashi. Check into the hotel on arrival in Guptakashi. Overnight stay at Guptakashi.",
        },
        {
          id: 2,
          place: "Guptkashi – Kedarnath (30kms by road & 19kms Trek one way) Height: 3384 MTS.",
          about:
            "Morning after breakfast, departure for Kedarnath Dham. Enjoy Kedarnath Darshan. Overnight stay at Kedarnath. By Helicopter: Transfer to your pre-booked Helipad. By Trek: Drop at Sonprayag, take a local jeep for Gaurikund, and start the trek to Kedarnath Ji.",
        },
        {
          id: 3,
          place: "Kedarnath- Guptkashi",
          about:
            "Morning after darshan, return to Guptkashi by track or helicopter. Overnight stay at the hotel.",
        },
        {
          id: 4,
          place: "Guptkashi - Haridwar (220kms/8 hrs)",
          about:
            "Morning after transfer to Haridwar. En-route visit Devprayag, the confluence of Alaknanda and Bhagirathi. Here onwards downstream river is called Ganges. Later transfer to Rishikesh - Shivananda Ashram, Ram Jhoola, Swarg Ashram, Geeta Ashram, Laxman Jhoola. Proceed to Haridwar, perform ritualistic bathing on the banks of the Ganges, and join the enchanting evening Aarti at Har-ki-Pauri. Drive to Haridwar railway station.",
        },
      ],
      description: {
        about: "Embark on a transformative journey with our Ekdham package, centered around the sacred haven of Kedarnath. Enveloped by the majestic Himalayas, Kedarnath beckons with its ancient temples and spiritual energy. Immerse yourself in the divine ambiance as you explore the mystical surroundings, offering a unique blend of nature and spirituality. Our carefully curated tour ensures a seamless pilgrimage experience, allowing you to connect with the essence of Kedarnath. Join us on this spiritual odyssey to rejuvenate your soul and create lasting memories amidst the serene landscapes of Ekdham.",
        departure: "Haridwar",
        departureTime: "5:00 pm",
      },
    },
    {
      title: " Chardham By Helicopter",
      img: "https://ttw.wlimg.com/package-images/photo-big/dir_23/667967/306998.jpg",
      interval: "6 days / 5 Nights",
      price: "₹1,85,000",
      readMore: "/chardham-package",
      timeline: [
        {
          id: 1,
          place: "Dehradun to Yamunotri",
          flyingTime: "30 Mins",
          departure: "Departure from SSD Helipad, Dehradun (By Helicopter)",
          arrival: "Arrival at Kharsali Helipad, Yamunotri",
        },
        {
          id: 2,
          place: "Yamunotri to Gangotri",
          flyingTime: "30 Mins",
          departure:
            "Departure from Kharsali Helipad, Yamunotri (By Helicopter)",
          arrival: "Arrival at Jhala Helipad, Gangotri",
        },
        {
          id: 3,
          place: "Gangotri to Kedarnath",
          flyingTime: "30 Mins",
          departure: "Departure from Harsil Helipad, Gangotri (By Helicopter)",
          arrival: "Arrival at Kedarnath Helipad (Base Camp)",
        },
        {
          id: 4,
          place: "Kedarnath to Badrinath",
          flyingTime: "30 Mins",
          departure: "Departure from Base Helipad, Kedarnath (By Helicopter)",
          arrival: "Arrival at Badrinath Helipad",
        },
        {
          id: 5,
          place: "Badrinath to Dehradun",
          flyingTime: "50 Mins",
          departure: "Departure from Badrinath Helipad (By Helicopter)",
          arrival: "Arrival at SSD Helipad, Dehradun (Will Provide Drop)",
        },
      ],

      description: {
        about:
        "Uttarakhand, also renowned as Devbhoomi or the Land of Gods, is dedicated home to a large number of temples and attracts pilgrims throughout the year. The greatest Char Dham Yatra tour packages provide a perfect opportunity to gratify your religious curiosity, observe the rich Hindu culture, and discover the realm's captivating natural view. The Char Dham Yatra plans encompass trips to Uttarakhand's holiest shrines. Our Yatra, or pilgrimage, introduces you to visiting four sacred destinations in the Himalayan Mountain range: notably Gangotri Dham, Yamunotri Dham, Kedarnath Dham, as well as Badrinath Dham. The very first place on the Char Dham route map is the Badrinath Dham, which itself is located along the riverbank of the Alaknanda River. Additional renowned attractions around Badrinath Dham to explore with Char Dham Yatra packages are Rudraprayag, Hemkund Sahib, Guptkashi, and Surya Kund. Puri Dham, approximately 60 kilometres from Bhubaneshwar, is the second visit on the Char Dham Yatra. Near Puri Dham, you may indeed view Narendra Pokhari, Gudicha Temple, and Puri Beach. The devotion proceeds with Rameshwaram Dham. An excellent Char Dham tour would let tourists to really see Dhanushkodi Temple, Agnitheertham, Pamban Bridge, and Jatayu Tirtham. The heavenly pilgrimage tour concludes in Dwarka Dham, which itself is recognized for the medieval Dwarkadhish Temple. High points of good Char Dham Yatra trip packages comprise travels to Beyt Island, Dwarka Beach, Gomti Ghat, and Gopi Talav.",
        departure: "Dehradun",
        departureTime: "5:00 pm",
      },
    },
    {
      title: " DoDham By Helicopter",
      img: "https://indiathrills.com/wp-content/uploads/2022/09/Helicopter-Services-1.jpg",
      interval: "6 days / 5 Nights",
      price: "₹1,00,000",
      readMore: "/chardham-package",
      timeline: [
        {
          id: 1,
          place: "Dehradun to Kedarnath",
          flyingTime: "30 Mins",
          departure: "Departure from SSD Helipad, Dehradun (By Helicopter)",
          arrival: "Arrival at Base Helipad, Kedarnath",
        },
        {
          id: 2,
          place: "Kedarnath to Badrinath",
          flyingTime: "30 Mins",
          departure: "Departure from Base Helipad, Kedarnath (By Helicopter)",
          arrival: "Arrival at Badrinath Helipad",
        },
        {
          id: 3,
          place: "Badrinath to Dehradun",
          flyingTime: "50 Mins",
          departure: "Departure from Badrinath Helipad (By Helicopter)",
          arrival: "Arrival at SSD Helipad, Dehradun",
        },
      ],
      description: {
        about: "Embark on a spiritual sojourn with our exclusive Dodham package, featuring the divine destinations of Kedarnath and Badrinath. Immerse yourself in the sacred aura of Kedarnath, nestled amidst the breathtaking Himalayas, where ancient temples and pristine landscapes await. Journey onward to Badrinath, a haven of tranquility and spirituality, where the revered Badrinath Temple stands as a testament to centuries of devotion. Our meticulously crafted tour ensures a seamless and enriching pilgrimage experience, allowing you to connect with the divine while exploring the cultural treasures of Dodham. Book your pilgrimage now for a soul-stirring adventure in the heart of the Himalayas.",
        departure: "Dehradun",
        departureTime: "5:00 pm",
      },
    },
  ];

  return (
    <>
      <Header />
      <h1 className="" style={{textAlign:'center'}}>Chardham</h1>
      <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </>
  );
}

//goa-2,andamn-4,himachla-3,uttra-2,rajs-4,kerala-1,ooty-1,north-east-4,kashmir-2,ladakh-1
