import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function TourSlides(props) {
    const navigate=useNavigate();
  return (
    <>
       <div class={props.i===1?'carousel-item active':'carousel-item '} >
                            <div class="row">

                                {
                                    props.Tour.map((tour,i)=>{
                                        return(
                                            <div class="col-md-4 mb-3" style={{cursor: "pointer"}} onClick={()=>{navigate(tour.link)}}>
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src={tour.imgUrl} style={{width:'500px',height:'300px'}}/>
                                        <div class="card-body">
                                            <h2 class="card-title">{tour.Title}</h2>
                                            {/* <p class="card-text">With supporting text below as a natural lead-in to additional content.</p> */}

                                        </div>
                                    </div>
                                </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
    </>
  )
}
