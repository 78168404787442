import React, { useEffect } from 'react'
import Header from '../Header'
import PackageCard from '../PackageCard'
import "../DomesticPackagespage/Package.css";
export default function Dubai() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
    const data = [
        {
          title: "Dubai",
          img: "https://cdn.tourcms.com/a/11676/2811/1/large.jpg",
          interval: "5 days / 4 Nights",
          price: "₹31,000",
          description: {
            about:
               "Empty",
            departure: "Dubai Airport",
            departureTime: "6:00 pm",
          },
          info: [ {
            about:
              "1. Marina Dhow Cruise with Buffet dinner.",
          },
           {
            about:
              "2.  Half day Dubai city tour Followed By the Dubai Mall, Burj Khalifa 124th floor non-prime with ticket and transfer.",
          },
           {
            about:
              "3.  Desert Safari with BBQ dinner and all other activities.",
          },
           {
            about:
              "4. Marina Dhow Cruise with Buffet dinner.",
          },
        
        ],
        },
    ]
        
  return (

    <>
        <Header/>
     <h1> Dubai</h1>
     <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </>
  )
}
