import React, { useEffect } from "react";
import PackageCard from "../PackageCard";
import Header from "../Header";

export default function AnadamanPackage() {
   useEffect(()=>{
   window.scrollTo(0,0);
   },[])
  const data = [
    {
      title: "Andaman & Nicobar",
      img: "https://th.bing.com/th/id/R.ead54ba3a7277bf0f6a72568e28c35e2?rik=4S0qNvhF1xfTZw&riu=http%3a%2f%2fwww.smartholidayshop.in%2fblog%2fwp-content%2fuploads%2f2017%2f04%2fnicobar-islands.jpg&ehk=nipfwamn8gnOl40pdCGa3YGCImbwlFfp0BiFclfluCc%3d&risl=&pid=ImgRaw&r=0",
      interval: "4 days / 3 Nights",
      price: "₹Null",
      readMore: "/chardham-package",
      description: {
        about: "Empty",
        departure: "Portblair",
        departureTime: "9:30",
      },
      timeline: [
        {
          id: 1,
          place: "Arrival",
          about:
            "Arrival at Port Blair. Visit Cellular Jail, Anthropological Museum, Naval Museum, Light and Sound Show, Flag Point, Carbyn Cove Beach.",
        },
        {
          id: 2,
          place: "Visit to Ross & Northbay Island",
          about:
            "Explore Ross Island and Northbay Island on the second day of your Andaman trip.",
        },
        {
          id: 3,
          place: "Travel from Port Blair",
          about:
            "Travel from Port Blair to Havelock. Visit Radhanagar Beach and Kalapathar Beach. Return to Port Blair.",
        },
        {
          id: 4,
          place: "Departure",
          about:
            "Check out from the hotel and depart for your onward destination. Trip concludes with unforgettable memories of Andaman.",
        },
      ]
    },
    {
      title: "Andaman & Nicobar",
      img: "https://tripsonwheels.com/wp-content/uploads/2018/06/Andaman-Nicobar.jpg",
      interval: "5 days / 4 Nights",
      price: "₹17,500",
      readMore: "/chardham-package",
      timeline: [
        {
          id: 1,
          place: "Arrival ",
          about:
            "Arrival at Port Blair. Visit Cellular Jail, Anthropological Museum, Naval Museum, Light and Sound Show, Flag Point, and Carbyn Cove Beach.",
        },
        {
          id: 2,
          place: "Visit to Ross & Northbay Island",
          about:
            "Explore Ross Island and Northbay Island on the second day of your trip.",
        },
        {
          id: 3,
          place: "Port Blair to Baratang Island - Visit to Lime Stone Cave & Mud Volcano",
          about:
            "Travel from Port Blair to Baratang Island. Visit the Lime Stone Cave and Mud Volcano.",
        },
        {
          id: 4,
          place: "Port Blair to Havelock - Radhanagar & Kalapathar Beach - Return to Port Blair",
          about:
            "Travel from Port Blair to Havelock. Visit Radhanagar Beach and Kalapathar Beach. Return to Port Blair.",
        },
        {
          id: 5,
          place: "Departure",
          about:
            "Departure from Port Blair. End of the trip with fond memories!",
        },
      ],
      description: {
        about: "Empty",
        departure:  "Portblair",
        departureTime: "9:30",
      },
    },
    {
      title: "Andaman & Nicobar",
      img: "https://th.bing.com/th/id/R.209c118ee5f0ce5017f26b0cb7666a44?rik=foV5WnrvcIrH2g&riu=http%3a%2f%2fshyamjitours.in%2fblog%2fwp-content%2fuploads%2f2018%2f03%2fexplore_andaman.jpg&ehk=Qef5iVd8hPCp%2bGG1QYuPvSzx49czmRGJ1Tr7sU5ldTg%3d&risl=&pid=ImgRaw&r=0",
      interval: "5 days / 4 Nights",
      price: "₹17,500",
      readMore: "/chardham-package",
      timeline: [
        {
          id: 1,
          place: "Arrival",
          about:
            "Day 1 – Welcome to Andaman! Upon arrival, visit Cellular Jail, Anthropology Museum, Naval Museum, attend the Light and Sound Show, visit Flag Point, and relax at Carbyn Cove Beach.",
        },
        {
          id: 2,
          place: "Visit to Ross & Northbay Island",
          about:
            "Day 2 – Explore Ross Island and Northbay Island. Enjoy the scenic beauty and water activities in Northbay Island.",
        },
        {
          id: 3,
          place: "Port Blair to Havelock - Radhanagar Beach",
          about:
            "Day 3 – Travel from Port Blair to Havelock. Visit the famous Radhanagar Beach, known for its stunning sunset views.",
        },
        {
          id: 4,
          place: "Havelock - Visit to Elephant Beach + 01 Session of Snorkeling (Included)",
          about:
            "Day 4 – Explore Elephant Beach and enjoy a session of snorkeling, included in the package.",
        },
        {
          id: 5,
          place: "Departure",
          about:
            "Day 5 – Bid farewell to the beautiful Andaman Islands. Check out and proceed for departure.",
        },
      ],
      description: {
        about: "Empty",
        departure:  "Portblair",
        departureTime: "9:30",
      },
    },
    {
      title: "Andaman & Nicobar",
      img: "https://images.thrillophilia.com/image/upload/s--WoW48X_r--/c_fill,f_auto,fl_strip_profile,h_800,q_auto,w_1300/v1/images/photos/000/112/428/original/1495611716_candle-lit-dinner-andaman.JPG.jpg?1495611716",
      interval: "6 days / 5 Nights",
      price: "₹20,000",
      readMore: "/chardham-package",
      description: {
        about: "Empty",
        departure:  "Portblair",
        departureTime: "9:30",
      },
      timeline: [
        {
          id: 1,
          place: "Arrival ",
          about:
            "Day 1 – Arrival in Andaman. Visit to Cellular Jail, Anthropological Museum, Naval Museum, Light and Sound Show at Cellular Jail, and Flag Point.",
        },
        {
          id: 2,
          place: "Visit to Ross & Northbay Island",
          about:
            "Day 2 – Explore Ross Island and Northbay Island, known for its coral reefs. Enjoy water activities and scenic views.",
        },
        {
          id: 3,
          place: "Port Blair to Havelock - Radhanagar & Kalapathar Beach - Return to Port Blair",
          about:
            "Day 3 – Travel from Port Blair to Havelock. Visit Radhanagar Beach and Kalapathar Beach. Return to Port Blair in the evening.",
        },
        {
          id: 4,
          place: "Havelock - Visit to Elephant Beach + 01 Session of Snorkeling (Included)",
          about:
            "Day 4 – Explore Elephant Beach in Havelock. Enjoy a session of snorkeling, included in the package.",
        },
        {
          id: 5,
          place: "Havelock to Port Blair - Shopping - Corbyn Cove Beach",
          about:
            "Day 5 – Travel from Havelock to Port Blair. Enjoy shopping and visit Corbyn Cove Beach.",
        },
        {
          id: 6,
          place: "Departure",
          about:
            "Day 6 – Departure from Andaman. Bid farewell to this beautiful destination with cherished memories.",
        },
      ],
    },
  ];
  return (
    <>
      <Header />
      <h1>Andaman & Nicobar</h1>
      <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </>
  );
}
