import React from 'react'
import logo from './images/logo.png'
import { Outlet, useNavigate } from 'react-router-dom'
import back from './images/back.jpg'
import Navbar from './Navbar'
import './Header.css'
export default function Header() {
    const navigate=useNavigate()
  return (
    <div >
     
    <div class='mybar' >
     <Navbar/>
    </div>
    </div>
  )
}
