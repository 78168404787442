import React from 'react'
import Header from './Header'
import BotCar from './VideoCarousel/BotCar'

export default function ClientReview() {
  return (
    <div>
      <Header/>
      <h1>Client Reviews</h1>
      <BotCar/>
    </div>
  )
}
