import React, { useEffect } from 'react'
import PackageCard from "../PackageCard";
import Header from "../Header";

export default function KeralaPackage() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
  const data = [
    {
      title: "Kerala",
      img: "https://th.bing.com/th/id/R.be7c0596fcea525866d35b8a504ae3d0?rik=Kr7l3ub0HhVjnQ&riu=http%3a%2f%2fcdn.wallpapersafari.com%2f15%2f26%2fCZMXvH.jpg&ehk=wQ7VzofvVAS41RTSfII0o2DIGP2FLG5pLCa7iDk80Uw%3d&risl=&pid=ImgRaw&r=0",
      interval: "6 days / 5 Nights",
      price: "₹17,500",
      readMore: "/chardham-package",
      timeline: [
        {
          id: 1,
          place: "Arrival Cochin- Munnar (150Kms / 4-5hrs)",
          about:
            "Arrival at Cochin airport and drive to Munnar. On the way to sightseeing like Chiyapara & Valara waterfalls, photo point and check in to the hotel, and overnight stay in the hotel.",
        },
        {
          id: 2,
          place: "Munnar",
          about:
            "After breakfast, explore local sightseeing of Munnar, visit Tea estates, Spice plantations, Echo point, Mattupetty/ Kundala dam, Rajamalai, Honey tree, etc and overnight stay in the hotel.",
        },
        {
          id: 3,
          place: "Munnar to Thekkady (110kms / 3-4 hrs)",
          about:
            "After breakfast, check out from the hotel and drive to Thekkady, and check in to the hotel, later proceed for local sightseeing like a boat ride through Periyar lake (Own Cost), where you can watch wildlife and overnight stay in the hotel.",
        },
        {
          id: 4,
          place: "Thekkady to Alleppey (130kms / 3-4 hrs)",
          about:
            "After breakfast, check out from Thekkady and drive to Alleppey. Check into Houseboat and lunch at Houseboat. Enjoy a houseboat ride and dinner and overnight at the houseboat.",
        },
        {
          id: 5,
          place: "Alleppey to Cochin",
          about:
            "After breakfast, check out from the hotel and drive to Cochin. On arrival check in to the hotel. Late afternoon explore the Local Sight Seeing Places Visit like Dutch Palace, Jewish Synagogue, Chinese Fishing Net, St. Francis Church, Marine Drive. Overnight stay at the hotel.",
        },
        {
          id: 6,
          place: "Cochin Airport",
          about:
            "After breakfast, check out from the hotel and transfer to Cochin airport.",
        },
      ],
      description: {
        about:
           "Empty",
        departure: "Cochin",
        departureTime: "9:30",
      },
    },
    {
      title: "Kerala",
      img: "https://www.sildaviaviajes.com/wp-content/uploads/2018/07/kerala1.jpg",
      interval: "5 days / 4 Nights",
      price: "₹15,200",
      readMore: "/chardham-package",
      timeline:[
        {
          day: "Day 01",
          place: "Pickup from Cochin Airport/Railway Station, proceed to Munnar (140 kms/4 hrs)",
          
          about: "Morning drive to Munnar hills, the green Paradise of Kerala with unending expands of Tea plantations, spice plantations and a variety of flora and fauna. On the way visit the water falls of Valara and Cheeyappara. Check in to hotel and after noon at leisure/ Visit nearby hotel/resort area. Overnight stay at Munnar."
        },
        {
          day: "Day 02",
          place: "Munnar Whole Day Sightseeing.",
        
          about: "After breakfast, go for sightseeing at Mattupetty Dam; a beautiful spot for viewing the picturesque Munnar, Kundala Lake- a natural lake with recreational facilities like boating and having an excellent view of Western Ghats as well as the forest surrounding. Also visit Photo Point, Eco point, and tea plantations. Afternoon visit to Eravikulam National park; where the endangering Nilgiri Tahr is conserved. Overnight stay at Munnar."
        },
        {
          day: "Day 03",
          place: "Munnar- Thekkady(100 kms/3 hrs)",
      
          about: "After breakfast check out from the hotel and drive towards Thekkady, the Wildlife destination of Kerala. It's also known as the spice bag of the state. Enjoy a beautiful journey through the hills and valleys of Western Ghat to reach this jungle destination. Boating at Periyar Lake to watch the wildlife. The chance to watch the wild animals like elephant, Bison, Sambar, Deer, etc., are roaming the both shore. Optional: Kathakali show, Spice plantation visit, elephant ride, elephant bath, Kalari Payattu Show, or an Ayurvedic massage treatment. Overnight stay at Thekkady."
        },
        {
          day: "Day 04",
          place: "Thekkady – Alleppey(Houseboat)",
         
          about: "Check in to a houseboat for an overnight cruise through the unique backwaters of Kerala. The houseboat will be the transportation for the day and accommodation for the night. Glide along the narrow canals laced by the infinite greenery imparted by coconut palms, watch the gentle and soothing greenery of paddy fields and the village folk at work. Relax on board the Houseboat."
        },
        {
          day: "Day 05",
          place: "Alleppey- Cochin( 75kms/2hrs)",
         
          about: "Transfer to Cochin, drop at Airport/Railway Station back home, feeling contented about a wonderful holiday you spent in the God's Own Country."
        }
      ],
      description: {
        about:
           "Empty",
        departure: "Cochin",
        departureTime: "9:30",
      },
    },
  ];
  return (
    <>
      <Header />
      <h1>Kerala</h1>

      <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </>
  );
}
