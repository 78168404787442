import PackageCard from "../PackageCard";
import Header from "../Header";
import React, { useEffect } from 'react'
export default function GoaPackage() {
  useEffect(()=>{
    window.scrollTo(0,0);
    },[])
  const data = [
    {
      title: "Goa",
      img: "https://media.vogue.in/wp-content/uploads/2016/03/goa-featured.jpg",
      interval: "4 days / 3 Nights",
      price: "₹9,000",
      readMore: "/chardham-package",
      description: {
        about:
           "Empty",
        departure: "Airport",
        departureTime: "9:30",
      },
      timeline: [
        {
          day: "Day 1",
          place: "Arrival at Goa Airport or Railway Station",
          about: "Upon arrival at Goa Airport/Railway Station, our representative will meet you, and you will be transferred to the hotel. Enjoy a welcome drink on arrival, check-in at the hotel, and spend the rest of the day at leisure. Evening sightseeing of Calangute beach as per your choice.",
        },
        {
          day: "Day 2",
          place: "South Goa Sightseeing",
          about: "After breakfast, proceed for a half-day sightseeing tour of South Goa, visiting Miramar Beach, Dona Paula Beach, Old Goa Churches, St.Francis Xavier Cathedral Churches, Mangueshi Temple, Shanta Durga Temple, and Dolphin Trip. Post-tour return to the hotel and enjoy the rest of the day at leisure. Overnight at the hotel.",
        },
        {
          day: "Day 3",
          place: "North Goa Sightseeing",
          about: "After breakfast, proceed for a half-day sightseeing tour of North Goa, visiting Aguada Fort, Saligao Church, Temple, Vagator Beach, Anjuna Beach, Morjim Beach, and Snow Park. Post-tour return to the hotel and enjoy the rest of the day at leisure. Overnight at the hotel.",
        },
        {
          day: "Day 4",
          place: "Departure from Goa",
          about: "After breakfast, check out from the hotel for the onward destination. The trip ends here with beautiful memories!!!",
        },
      ],
      
    },
    {
      title: "Goa",
      img: "https://i0.wp.com/networkustad.com/wp-content/uploads/2021/01/india-3882103_1920.jpg?w=1920&ssl=1",
      interval: "5 days / 4 Nights",
      price: "₹11,500",
      readMore: "/chardham-package",
      timeline: [
        {
          id: 1,
          place: "Arrival at Goa Airport or Railway Station",
          about:
            "Once Arrival at Goa Airport / Railway Station our representative will meet you & will transfer to Hotel. Welcome drink on arrival, Check-in at the hotel and enjoy the rest of the day at leisure. Enjoy the Evening sightseeing of Calangute beach as per your choice.",
        },
        {
          id: 2,
          place: "South Goa Sightseeing",
          about:
            "After breakfast proceed for half day sightseeing tour of South Goa visiting Miramar Beach, Dona Paula Beach, Old Goa Churches, St.Francis, Xavier Cathedral Churches, Mangueshi Temple, Shanta Durga Temple, Dolphin Trip. Post tour return to hotel and enjoy the rest of the day at leisure. Overnight at the hotel.",
        },
        {
          id: 3,
          place: "North Goa Sightseeing",
          about:
            "After breakfast proceed for half day sightseeing tour of North Goa visiting Aguada Fort, Saligao Church, Temple, Vagator Beach, Anjuna Beach, Morjim Beach, Snow Park. Post tour return to hotel and enjoy the rest of the day at leisure. Overnight at the hotel.",
        },
        {
          id: 4,
          place:
            "Day at leisure or Optional Dudhsagar Waterfall & Spice Plantation",
          about:
            "After breakfast, enjoy the rest of the day at Very famous Dudhsagar Waterfall Tour which includes a drive on dirt tracks through streams. Overnight at the hotel.",
        },
        {
          id: 5,
          place: "Departure from Goa",
          about:
            "After breakfast check out from the hotel for onward destination. Trip ends here with beautiful memories!!!",
        },
      ],
      description: {
        about:
           "Empty",
        departure: "Airport",
        departureTime: "9:30",
      },
    },
    {
      title: "Goa HoneyMoon",
      img: "https://www.holidaysplanners.com/wp-content/uploads/2018/03/best-goa-honeymoon-places.jpg",
      interval: "4 days / 3 Nights",
      price: "₹13,500",
      readMore: "/chardham-package",
      description: {
        about:
           "Empty",
        departure: "Airport",
        departureTime: "9:30",
      },
      timeline: [
        {
          day: "Day 1",
          place: "Arrival at Goa Airport or Railway Station",
          about: "Upon arrival at Goa Airport/Railway Station, our representative will meet you, and you will be transferred to the hotel. Enjoy a welcome drink on arrival, check-in at the hotel, and spend the rest of the day at leisure. Evening sightseeing of Calangute beach as per your choice.",
        },
        {
          day: "Day 2",
          place: "South Goa Sightseeing",
          about: "After breakfast, proceed for a half-day sightseeing tour of South Goa, visiting Miramar Beach, Dona Paula Beach, Old Goa Churches, St.Francis Xavier Cathedral Churches, Mangueshi Temple, Shanta Durga Temple, and Dolphin Trip. Post-tour return to the hotel and enjoy the rest of the day at leisure. Overnight at the hotel.",
        },
        {
          day: "Day 3",
          place: "North Goa Sightseeing",
          about: "After breakfast, proceed for a half-day sightseeing tour of North Goa, visiting Aguada Fort, Saligao Church, Temple, Vagator Beach, Anjuna Beach, Morjim Beach, and Snow Park. Post-tour return to the hotel and enjoy the rest of the day at leisure. Overnight at the hotel.",
        },
        {
          day: "Day 4",
          place: "Departure from Goa",
          about: "After breakfast, check out from the hotel for the onward destination. The trip ends here with beautiful memories!!!",
        },
      ],
    },
  ];
  return (
    <>
      <Header />
      <h1>Goa</h1>
      <div className="package-card">
        <div className="package-container">
          {data.map((packageData, index) => (
            <PackageCard key={index} data={packageData} />
          ))}
        </div>
      </div>
    </>
  );
}
